
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Dialog, DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { deleteNotification, getFilterNotification, sendNotification } from '../../service/notification';
import { useEffect } from 'react';
import { localDate } from '../../service/validation';



function PushNotification() {

  let initialStateInputs = {
    title: "",
    message: "",
    date:"",
    isActive:""
  }

  let initialStateErrors = {
    title: { required: false },
    message: { required: false },
  };
  const pageSize = 10
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  const [errors, setErrors] = useState(initialStateErrors);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState(initialStateInputs);
  const [filter, setFilter] = useState(false)
  const [remove, setRemove] = useState(false);
  const [notification, setNotification] = useState();
  const [add,setAdd]=useState(false)


  useEffect(() => {
    filter ? filterNotificationList() : getAllNotificationList();
  }, [pagination.from, pagination.to]);

  const getAllNotificationList = () => {
    const data = {
      limit: 10,
      page: pagination.from,
    }
    getFilterNotification(data)
      .then((res) => {
        setNotification(res?.data?.result?.notificationList);
        setPagination({ ...pagination, count: res?.data?.result?.notificationCount })

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const openFilterPopup = () => {
    setOpen(true)
  }

  const closeFilterPopup = () => {
    setOpen(false)
  }

  const filterNotificationList = (event) => {
    event?.preventDefault()
    setFilter(true);
    const data = {
      title: inputs?.title,
      date: inputs?.date,
      isActive: inputs?.isActive,
      limit: 10,
      page: pagination.from,
    }
    getFilterNotification(data)
      .then((res) => {
        setNotification(res?.data?.result?.notificationList);
        setPagination({ ...pagination, count: res?.data?.result?.notificationCount })
        closeFilterPopup()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const resetFilter = () => {
    setFilter(false)
    setInputs(initialStateInputs)
    getAllNotificationList()
  }


  const openPopup = () => {
    setInputs(initialStateInputs)
    setAdd(true);
  };
  const closePopup = () => {
    setAdd(false);
  };

  const resetForm = () => {
    setInputs(initialStateInputs)
  }

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (inputs.title === "") {
      error.title.required = true;
      hashError = true;
    }
    if (inputs.message === "") {
      error.message.required = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      sendNotification(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          closePopup();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const openDeletePopup = (data) => {
    setRemove(true)
    setInputs({ ...inputs, _id: data })
  }

  const closeDeletePopup = () => {
    setRemove(false)
  }

  const deleteNotificationData = () => {
    deleteNotification(inputs._id).then(res => {
      toast.success(res?.data?.message);
      closeDeletePopup();
      getAllNotificationList()
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Push Notification</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link className="btn-filters" onClick={openFilterPopup}>
                        <span>
                          <i className="fe fe-filter"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn-pix-primary " onClick={openPopup} >
                        <i className="fa fa-plus-circle me-2" aria-hidden="true"></i> Send Notification</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="card-table table-responsive">
                      <table className="card-table dataTable">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {notification?.map((data, index) =>
                            <tr key={index}>
                              <td>#{pagination.from + index + 1}</td>
                              <td>{data?.title}</td>
                              <td>
                                <p>{data?.description} </p>
                              </td>
                              <td>{localDate(data?.date)}</td>
                              <td>
                                {data?.isActive ? (
                                  <span className="badge-soft-success">
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge-soft-danger">
                                    Inactive
                                  </span>
                                )}
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <Link className="action-icon dropdown-toggle" onClick={() => { openDeletePopup(data?._id) }} ><i className="far fa-trash-alt"></i></Link>
                                </div>
                              </td>
                            </tr>)}
                          {notification?.length === 0 ?
                            <tr>
                              <td className="form-text text-danger">
                                No data
                              </td>
                            </tr> : null}
                        </tbody>
                      </table>
                    </div>
                    <div className='float-end my-2'>
                      <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={add} fullWidth maxWidth="sm">
        <DialogTitle>
          Send Notification
          <IconButton className="float-end" onClick={closePopup} >
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="from-group mb-3">
              <label htmlFor="Title" className="form-label">
                Title <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="Title"
                name="title"
                value={inputs.title}
                onChange={handleInputs}
                placeholder="Enter Title"
              />
              {errors.title.required ? <span className="form-text text-danger">
                This field is required.
              </span> : null}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group notes-form-group">
                <label htmlFor='Message'>Message <span className="text-danger">*</span></label>
                <textarea id='Message' name='message' value={inputs?.message} onChange={handleInputs} className="form-control" placeholder="Enter Your Message"></textarea>
                {errors.message.required ? <span className="form-text text-danger">
                  This field is required.
                </span> : null}
              </div>
            </div>
            <div><button type="button" onClick={resetForm} className="btn btn-cancel float-end">Reset</button>
              <button type="submit" className="btn btn-save float-end mx-2">Send</button></div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Filter Notification
          <IconButton className="float-end" onClick={closeFilterPopup} >
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className="from-group mb-3">
              <label htmlFor="Title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="Title"
                name="title"
                value={inputs?.title}
                onChange={handleInputs}
                placeholder="Enter Title"
              />
            </div>
            <div className="from-group mb-3">
              <label htmlFor="Date" className="form-label">
                Date
              </label>
              <input
                type="date"
                className="form-control"
                id="Date"
                name="date"
                value={inputs?.date}
                onChange={handleInputs}
                placeholder="Enter Date"
              />
            </div>
            <div className="from-group mb-3">
              <label className="form-label">
                Status
              </label>
              <select className="form-select" value={inputs?.isActive ?? ''} onChange={handleInputs} name='isActive'>
                <option value={""} disabled hidden >Select Status</option>
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
            <div><button type="button" className="btn btn-cancel  float-end" onClick={resetFilter}>Reset</button>
              <button type="submit" onClick={filterNotificationList} className="btn btn-save float-end mx-2">Apply</button></div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={remove}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Notification ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={deleteNotificationData}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>

    </>
  );
}
export default PushNotification;