
import { useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import {  s3Url } from '../../service/FileUpload';
import { saveUser } from '../../service/user';
import { toast } from 'react-toastify';
import { isValidEmail, isValidPhone } from '../../service/validation';
import { Link, useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';

function AddUser() {
  const navigate = useNavigate()
  let initialStateInputs = {
    name: "",
    userName: "",
    dob: "",
    phone: "",
    email: "",
    gender: "Male",
    isActive: true,
    isPrivate: false,
    isAdmin: true,
    aboutMe: "",
    key:'empty_profile.png',
    imageUrl: 'https://s3.ap-south-1.amazonaws.com/pixalive.me/empty_profile.png',
  }

  let initialStateErrors = {
    userName: { required: false },
    name: { required: false },
    phone: { required: false, valid: false },
    email: { valid: false }
  };
  const [errors, setErrors] = useState(initialStateErrors);
  const [inputs, setInputs] = useState(initialStateInputs);

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      Storage.put(file.name, file).then(res => {
				var url = `${s3Url}public/` + res.key;
				var key = 'public/' + res.key;
        setInputs({ ...inputs, imageUrl: url,key })
			}).catch(err => { console.log(err)})
    }
  };

  const isActive = (event) => {
    let status = event.target.value === 'Active' ? true : false
    setInputs({ ...inputs, isActive: status })
  };

  const isPrivate = (event) => {
    let status = event.target.value === 'Private' ? true : false
    setInputs({ ...inputs, isPrivate: status })
  };

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target?.name]: event.target.value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (inputs.name === "") {
      error.name.required = true;
      hashError = true;
    }
    if (inputs.userName === "") {
      error.userName.required = true;
      hashError = true;
    }
    if (!isValidPhone(inputs.phone)) {
      error.phone.valid = true
      hashError = true;
    }
    if (!isValidEmail(inputs.email)) {
      error.email.valid = true
      hashError = true;
    }
    if (inputs.phone === "") {
      error.phone.required = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      saveUser(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/UploadContent")
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header mb-20">
                <h5>Add User</h5>
                <ul className="breadcrumb">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">Upload Content</Link></li>
                    <li className="breadcrumb-item active">Add User</li>
                  </ul>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="form-group-item">
                            <div className="row">
                              <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="upload-img">
                                  <label>Upload Profile</label>
                                  <div>
                                    <img className="rounded-circle avatar-lg img-thumbnail" alt="" src={(s3Url+inputs?.key)} />
                                  </div>
                                  <div className="p-image">
                                    <label htmlFor="file-input"><i className="fa fa-camera pointer" aria-hidden="true"></i></label>
                                    <input name='imageUrl' id="file-input" type="file" style={{ width: '0' }} onChange={handleFileInputs} accept="image/*" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Full Name</label>
                                  <input type="text" name='name' onChange={handleInputs} className="form-control" placeholder="Enter Full Name" />
                                  {errors.name.required ? <span className="form-text text-danger">
                                    This field is required.
                                  </span> : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>User Name</label>
                                  <input type="text" name='userName' onChange={handleInputs} className="form-control" placeholder="Enter User Name" />
                                  {errors.userName.required ? <span className="form-text text-danger">
                                    This field is required.
                                  </span> : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Phone No</label>
                                  <input type="text" id="mobile_code" onChange={handleInputs} className="form-control" placeholder="Enter Phone No" name="phone" />
                                  {errors.phone.required ? (
                                    <span className="text-danger form-text">
                                      This field is required.
                                    </span>
                                  ) : errors.phone.valid ? (
                                    <span className="text-danger form-text">
                                      Enter valid Phone Number.
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Email Id</label>
                                  <input type="text" name='email' onChange={handleInputs} className="form-control" placeholder="Enter Email Id" />
                                  {errors.email.required ? (
                                    <span className="text-danger form-text">
                                      This field is required.
                                    </span>
                                  ) : errors.email.valid ? (
                                    <span className="text-danger form-text">
                                      Enter valid Email Id.
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>DOB(Date of Birth)</label>
                                  <input type="date" name='dob' onChange={handleInputs} className="form-control" placeholder="Enter DOB" />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Gender</label>
                                  <select className="form-select" onChange={handleInputs} name='gender'>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Account Info</label>
                                  <select className="form-select" onChange={handleInputs} name='isPrivate'>
                                    <option value={false}>Public</option>
                                    <option value={true}>Private</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Status</label>
                                  <select className="form-select" onChange={handleInputs} name='isActive'>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group notes-form-group">
                                  <label>Bio</label>
                                  <textarea name='aboutMe' onChange={handleInputs} className="form-control" placeholder="Enter Your Description"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="add-customer-btns text-end">
                            <Link type='button' to="/UploadContent" className="btn btn-cancel">Cancel</Link>
                            <button type='submit' className="btn btn-save">Save</button>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default AddUser;