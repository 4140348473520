import React, { useState } from "react";
import { login } from "../../service/login";
import { saveToken, saveUserId } from "../../service/Storage";
import { isAuthenticated } from "../../service/Auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isValidEmail, isValidPassword } from "../../service/validation";

function Login() {
  const navigate = useNavigate();
  let initialStateErrors = {
    email: { required: false, valid: false },
    password: { required: false, valid: false },
  };

  const [errors, setErrors] = useState(initialStateErrors);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (inputs.email === "") {
      error.email.required = true;
      hashError = true;
    }
    if (!isValidEmail(inputs.email)) {
      error.email.valid = true;
      hashError = true;
    }
    if (inputs.password === "") {
      error.password.required = true;
      hashError = true;
    }
    if (inputs.password && !isValidPassword(inputs.password)) {
      error.password.valid = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      login(inputs)
        .then((res) => {
          let token = res?.data?.result?.token;
          let userId = res?.data?.result?.userDetails?._id;
          let data = {
            token:token,_id:userId
          }
           saveToken(data);
          if (isAuthenticated()) {
            navigate("/Dashboard");
          }
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  if (isAuthenticated()) {
    return <Navigate to="/Dashboard" />;
  }

  return (
    <>
      <section className="auth-page-wrapper position-relative d-flex align-items-center justify-content-center min-vh-100">
        <div className="card mb-0">
          <div className="row g-0 align-items-center">
            <div className="col-xl-5">
              <div className="auth-card bg-pixalive shadow-none d-none d-sm-block mb-0">
                {/* <div className="mask mask-1"></div> */}
                <div className="card-body py-3 d-flex justify-content-between flex-column">
                  <a href="/#" className="mb-0 mb-lg-20 text-center login-logo">
                    <img alt="Logo" src="../assets/images/logo-light.svg" />
                  </a>

                  <div className="auth-effect-main my-5 position-relative rounded-circle d-flex align-items-center justify-content-center mx-auto">
                    <div className="effect-circle-1 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                      <div className="effect-circle-2 position-relative mx-auto rounded-circle d-flex align-items-center justify-content-center">
                        <div className="effect-circle-3 mx-auto rounded-circle position-relative text-white fs-4xl d-flex align-items-center justify-content-center">
                          Welcome to{" "}
                          <strong className="text-primary-1 ms-1">
                            Pixalive
                          </strong>
                        </div>
                      </div>
                    </div>
                    <ul className="auth-user-list list-unstyled">
                      <li>
                        <div className="avatar-sm d-inline-block">
                          <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                            <img
                              src="../assets/images/users/avatar-1.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="avatar-sm d-inline-block">
                          <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                            <img
                              src="assets/images/users/avatar-2.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="avatar-sm d-inline-block">
                          <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                            <img
                              src="assets/images/users/avatar-3.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="avatar-sm d-inline-block">
                          <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                            <img
                              src="assets/images/users/avatar-4.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="avatar-sm d-inline-block">
                          <div className="avatar-title bg-white shadow-lg overflow-hidden rounded-circle">
                            <img
                              src="assets/images/users/avatar-5.jpg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="text-center">
                    <h3 className="text-white mb-2">
                      Connecting with Like-Minded People on Pixalive
                    </h3>
                    <p className="text-white opacity-75 fs-base">
                      Empowering Users, Creators, Influencers, Businesses in the
                      Social Media Landscape
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 mx-auto">
              <div className="card mb-0 border-0 shadow-none mb-0 ">
                <div className="card-body p-sm-5">
                  <div className="text-center mt-2">
                    <h5 className="fs-3xl">Welcome Back</h5>
                    <p className="text-muted">
                      Sign in to continue to Pixalive.
                    </p>
                  </div>
                  <div className="p-2 mt-3">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                          Email Id <span className="text-danger">*</span>
                        </label>
                        <div className="position-relative ">
                          <input
                            onChange={handleInputs}
                            type="text"
                            className="form-control  password-input"
                            id="username"
                            name="email"
                            placeholder="Enter Email Id"
                          />
                        </div>
                        {errors.email.required ? (
                          <span className="text-danger form-text">
                            Email id is required.
                          </span>
                        ) : errors.email.valid ? (
                          <span className="text-danger form-text">
                            Enter valid Email id.
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="password-input">
                          Password <span className="text-danger">*</span>
                        </label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            onChange={handleInputs}
                            type="password"
                            className="form-control pe-5 password-input "
                            placeholder="Enter password"
                            id="password-input"
                            name="password"
                          />
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                          {errors.password.required ? (
                            <span className="text-danger form-text">
                              Password is required.
                            </span>
                          ) : errors.password.valid ? (
                            <span className="text-danger form-text">
                              A minimum 8 characters password contains a
                              combination of {''}
                              <strong>uppercase and lowercase letter {''}</strong>
                              and <strong>number</strong>.
                            </span>
                          ) : null}
                        </div>
                        <div className="float-end">
                          <Link to="/ForgotPassword" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="auth-remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-pix-login w-100"
                          type="submit"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>

                    <div className="text-center mt-3">
                      <p className="mb-0">Keep your interest alive</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;
