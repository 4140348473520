import axios from './interceptor'

export const getAllPlays = (data) => {
    return axios.put("/play/getFilterAdminPlay", data);
};

export const getSinglePlay = (data) => {
    return axios.get("/play/getSinglePlay", { params: { _id: data } });
};

export const savePlay = (data) => {
    return axios.post("/play",data);
  };

  export const updatePlay = (data) => {
    return axios.put("/play",data);
  };

  export const deletePlay = (data) => {
    return axios.delete("/play",{params:{_id:data}});
  };

  export const getFilterBidPlay = (data) => {
    return axios.put("/play/getFilterBidPlay",data);
  };

  export const getFilterPlayReport = (data) => {
    return axios.put("/play/getFilterPlayReport",data);
  };

  export const updatePlayStatus = (data) => {
    return axios.put("/play/updatePlayActiveStatus",data);
  };