import axios from './interceptor';

export const login = (inputs) => {
  return axios.post("/adminLogin", inputs);
};

export const forgotPassword = (inputs) => {
  var data = {
    email: inputs.email,
    link: "https://admin.pixalive.me/ResetPassword?id=",
  };
  return axios.put("/adminLogin/forgotPassword", data);
};


export const resetPassword = (inputs) => {
  return axios.put("/adminLogin/resetPassword", inputs);
};