
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { getSingleUser } from '../../service/user';
import { Link, useLocation } from 'react-router-dom';
import { localDate } from '../../service/validation';
import { s3Url } from '../../service/FileUpload';

function UserView () { 
  const [user, setUser] = useState()
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get('id');
  useEffect(() => {
    getUserDetail()
  }, [])
  const getUserDetail = () => {
    getSingleUser(userId).then(res => {
      const user = res?.data?.result
      setUser({ ...user, user })
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header page-header mb-20">
                <div className="col">
                  <h5>User Details</h5>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">User List</Link></li>
                    <li className="breadcrumb-item active">User Details</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="student-profile-head">
                      <div className="profile-bg-img"></div>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="profile-user-box">
                            <div className="profile-user-img">
                              <img src={s3Url+user?.key} alt="Profile" />
                            </div>
                            <div className="names-profiles">
                              <h4>{user?.userName}</h4>
                              <h5>{user?.userId}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 d-flex align-items-center">
                          <div className="follow-group">
                            <div className="students-follows">
                              <h5>Posts</h5>
                              <h4>{user?.postCount}</h4>
                            </div>
                            <div className="students-follows">
                              <h5>Plays</h5>
                              <h4>{user?.playCount}</h4>
                            </div>
                            <div className="students-follows">
                              <h5>Followers</h5>
                              <h4>{user?.followersCount}</h4>
                            </div>
                            <div className="students-follows">
                              <h5>Following</h5>
                              <h4>{user?.followingCount}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="student-personals-grp">
                      <div className="card">
                        <div className="card-body">
                          <div className="heading-detail">
                            <h4>Personal Details :</h4>
                          </div>
                          <div className='row'>
                          <div className="col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Full Name</h4>
                              <h5>{user?.name}</h5>
                            </div>
                          </div>
                          <div className="col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>User Name</h4>
                              <h5>{user?.userName}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Phone No</h4>
                              <h5>{user?.phone}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Email</h4>
                              <h5>{user?.email}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Registration Date</h4>
                              <h5>{localDate(user?.createdOn)}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>DOB(Date of Birth)</h4>
                              <h5>{user?.dob}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Gender</h4>
                              <h5>{user?.gender}</h5>
                            </div>
                          </div>

                          <div className=" col-lg-3 personal-activity">
                            <div className="views-personal">
                              <h4>Account Info</h4>
                              <h5>{user?.isPrivate ? 'Private' : 'Public'}</h5>
                            </div>
                          </div>
                          <div className=" col-lg-3 personal-activity mb-0">
                            <div className="views-personal">
                              <h4>Status</h4>
                              <h5>{user?.isActive ? 'Active' : 'Inactive'}</h5>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="student-personals-grp">
                      <div className="card mb-0">
                        <div className="card-body">
                          <div className="heading-detail">
                            <h4>About Me</h4>
                          </div>
                          <div className="hello-park">
                            <p>{user?.aboutMe}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  } 
  export default UserView;