import axios from './interceptor';

export const getAllUsers = () => {
  return axios.get("/user/getAllUsersFromAdmin");
};


export const getFilterUsers = (data) => {
  return axios.put("/user/getFilterUsersFromAdmin", data);
};

export const getAllTrendingUser = (data) => {
  return axios.put("/user/getFilterAdminTrendingUsers", data);
};

export const saveUser = (data) => {
  return axios.post("/user", data);
};

export const updateUser = (data) => {
  return axios.put("/user/updateUserFromAdmin", data);
};

export const getSingleUser = (data) => {
  return axios.get("/user/getSingleUser", { params: { _id: data } });
};

export const deleteUser = (data) => {
  return axios.delete("/user", { params: { _id: data } });
};

export const updateUserRank = (data) => {
  return axios.put("/user/updateUserRank", data);
};

export const updateUserStatus = (data) => {
  return axios.put("/user/updateUserActiveStatus",data);
};