import { useEffect, useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { getAllTrendingUser, updateUserRank } from "../../service/user";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { localDate } from "../../service/validation";
import { templatePdf } from "../../service/pdfmake";
import { ExportCsvService } from "../../service/excel";
import { toast } from "react-toastify";
import { s3Url } from "../../service/FileUpload";

function TrendingPeople() {
  const initialStateInputs = {
    userName: "",
    points: "",
  };
  const userRank = [
    { rank: '1', user: "", error: false },
    { rank: '2', user: "", error: false },
    { rank: '3', user: "", error: false },
  ];
  const pageSize = 8;
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize,
  });


  const [trendingPeople, setTrendingPeople] = useState();
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState(initialStateInputs);
  const [user, setUser] = useState([]);
  const [addRank, setAddRank] = useState();
  const [rank, setRank] = useState(userRank)
  const [error, setError] = useState(userRank)

  const data = {
    limit: 8,
    page: pagination.from,
  };

  useEffect(() => {
    filter ? filterTrendingUserList() : getTrendingPeople();
  }, [pagination.from, pagination.to]);

  const getTrendingPeople = () => {
    getAllTrendingUser(data)
      .then((res) => {
        setTrendingPeople(res?.data?.result?.trendingUserList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.trendingUserCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };

  const openFilterPopup = () => {
    getAllTrendingUserList();
    setOpen(true);
  };

  const closeFilterPopup = () => {
    setOpen(false);
  };

  const filterTrendingUserList = (event) => {
    setFilter(true);
    event.preventDefault();
    var data = {
      points: inputs?.points,
      userName: inputs?.userName,
      limit: 8,
      page: pagination.from,
    };
    getAllTrendingUser(data)
      .then((res) => {
        setTrendingPeople(res?.data?.result?.trendingUserList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.trendingUserCount,
        });
        closeFilterPopup();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetFilter = () => {
    setFilter(false);
    setInputs(initialStateInputs);
    getTrendingPeople();
  };

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const getAllTrendingUserList = () => {
    getAllTrendingUser("")
      .then((res) => {
        const userList = res?.data?.result?.trendingUserList;
        setUser(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openRankPopup = () => {
    getAllTrendingUserList()
    setAddRank(true);
  };

  const closeRankPopup = () => {
    setAddRank(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    const error = userRank
    let hashError = false
    rank.forEach((x, index) => {
      if (x.user === "") {
        error[index].error = true
        hashError = true
      }
    })
    setError(error)

    if (!hashError) {
      const data = { rank: rank }
      updateUserRank(data)
        .then((res) => {
          toast.success(res?.data?.message);
          closeRankPopup();
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleRank = (event) => {
    const userId = event.target.value
    const name = event.target.name
    const userList = rank.find(x => x.user === userId)
    if (!userList) {
      const result = [...rank]
      result.forEach(y => {
        if (y.rank === name) {
          y.user = userId
        }
      })
      setRank(result)
    }
    else {
      toast.error(`You've already chosen this user.`)
    }

  }

  const resetForm = ()=>{
    setRank(userRank)
  }

  const pdfDownload = () => {
    getAllTrendingUser("")
      .then((res) => {
        var result = res?.data?.result?.trendingUserList;
        var tablebody = [];
        tablebody.push([
          {
            text: "S.NO",
            fontSize: 11,
            alignment: "center",
            margin: [5, 5],
            bold: true,
          },
          {
            text: "User Id",
            fontSize: 11,
            alignment: "center",
            margin: [40, 5],
            bold: true,
          },
          {
            text: "User Name",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Phone No",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Registration Date",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Points",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Following",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Post",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
          {
            text: "Followers",
            fontSize: 11,
            alignment: "center",
            margin: [20, 5],
            bold: true,
          },
        ]);
        result.forEach((element, index) => {
          tablebody.push([
            {
              text: index + 1,
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
              border: [true, false, true, true],
            },
            {
              text: element?.userId,
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.userName?.toUpperCase(),
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.phone,
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: localDate(element?.createdOn) ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.points ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.followingCount ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.postCount ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
            {
              text: element?.followersCount ?? "-",
              fontSize: 10,
              alignment: "left",
              margin: [5, 3],
            },
          ]);
        });
        templatePdf("Trending User List", tablebody, "landscape");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportCsv = () => {
    getAllTrendingUser("")
      .then((res) => {
        var result = res?.data?.result?.trendingUserList;
        let list = [];
        result?.forEach((res) => {
          list.push({
            userId: res?.userId,
            userName: res?.userName?.toUpperCase() ?? "-",
            phone: res?.phone ?? "-",
            registrationDate: localDate(res?.createdOn) ?? "-",
            points: res?.points ?? "-",
            following: res?.followingCount ?? "-",
            post: res?.postCount ?? "-",
            followers: res?.followersCount ?? "-",
          });
        });
        let header1 = [
          "userId",
          "userName",
          "phone",
          "registrationDate",
          "points",
          "following",
          "post",
          "followers",
        ];
        let header2 = [
          "User Id",
          "User Name",
          "Phone No",
          "Registration Date",
          "Points",
          "Following",
          "Post",
          "Followers",
        ];
        ExportCsvService.downloadCsv(
          list,
          "trendinguserlist",
          "Trending User List",
          header1,
          header2
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Trending People</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link className="btn-filters" onClick={pdfDownload}>
                        <span>
                          <i className="fa fa-file-pdf" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={exportCsv}>
                        <span>
                          <i
                            className="fa fa-file-excel"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={openFilterPopup}>
                        <span>
                          <i className="fe fe-filter"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <button
                        className="btn btn-pix-primary "
                        onClick={openRankPopup}
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        ></i>
                        Change Rank{" "}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="friend-list-box">
                  <div className="friend-list friend-page-list">
                    <ul>
                      {trendingPeople?.map((data, index) => (
                        <li key={index}>
                          <div className="profile-box friend-box">
                            <div className="profile-content">
                              <div className=" half-circle">
                                {pagination.from + index + 1}
                              </div>
                              <div className="image-section">
                                <div className="profile-img">
                                  <div className="bg-size">
                                    <img
                                      src={s3Url+data?.key}
                                      width={53}
                                      alt="profile"
                                    />
                                  </div>
                                  <span className="stats">
                                    <img
                                      src="../assets/images/verified.png"
                                      className="img-fluid"
                                      alt="verified"
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="profile-detail">
                                <h2>
                                  {data?.userName}
                                  <span>❤</span>
                                </h2>
                                <h5>{data?.points} Points</h5>
                                <div className="counter-stats">
                                  <ul>
                                    <li>
                                      <h3 className="counter-value">
                                        {data?.followingCount}
                                      </h3>
                                      <h5>following</h5>
                                    </li>
                                    <li>
                                      <h3 className="counter-value">
                                        {data?.postCount}
                                      </h3>
                                      <h5>Post</h5>
                                    </li>
                                    <li>
                                      <h3 className="counter-value">
                                        {data?.followersCount}
                                      </h3>
                                      <h5>followers</h5>
                                    </li>
                                  </ul>
                                </div>
                                <Link
                                  to={{
                                    pathname: "/UserDetail",
                                    search: `?id=${data?._id}&rank=${pagination.from + index + 1
                                      }`,
                                  }}
                                  className="btn btn-profile-view"
                                >
                                  view profile
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                      {trendingPeople?.length === 0 ? (
                        <tr>
                          <td className="form-text text-danger">No data </td>
                        </tr>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="float-end my-2">
                  <Pagination
                    count={Math.ceil(pagination.count / pageSize)}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Filter Trending Users
          <IconButton className="float-end" onClick={closeFilterPopup}>
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className="form-group">
              <label>User Name</label>
              <select
                className="form-select"
                value={inputs?.userName ?? ""}
                onChange={handleInputs}
                name="userName"
              >
                <option value={""} disabled hidden>
                  Select User
                </option>
                {user?.map((data, index) => (
                  <option key={index} value={data?._id}>
                    {data?.userName}
                  </option>
                ))}
              </select>
            </div>
            <div className="from-group mb-3">
              <label htmlFor="point" className="form-label">
                Points
              </label>
              <input
                type="text"
                className="form-control"
                id="point"
                name="points"
                value={inputs?.points}
                onChange={handleInputs}
                placeholder="Enter Points"
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-cancel  float-end"
                onClick={resetFilter}
              >
                Reset
              </button>
              <button
                type="submit"
                onClick={filterTrendingUserList}
                className="btn btn-save float-end mx-2"
              >
                Apply
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={addRank} fullWidth maxWidth="sm">
        <DialogTitle>
          Change Rank
          <IconButton className="float-end" onClick={closeRankPopup}>
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            {rank?.map((data, index) => (
              <div key={index} className="from-group mb-3">
                <label htmlFor="categoryName" className="form-label">
                  Rank {data?.rank} <span className="text-danger">*</span>
                </label>
                <select
                  className="form-select"
                  name={data?.rank} onChange={handleRank} value={data?.user}
                >
                  <option value={""} disabled hidden>
                    Select Trending User
                  </option>
                  {user?.map((data1, index1) => (
                    <option key={index1} value={data1?._id}>
                      {data1?.userName}
                    </option>
                  ))}
                </select>
                {error[index]?.error ? <span className="form-text text-danger">
                  This field is required.
                </span> : null}
              </div>
            ))}
            <div>
              <button onClick={resetForm} type="button" className="btn btn-cancel float-end">
                Reset
              </button>
              <button type="submit" className="btn btn-save float-end mx-2">
                Save
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default TrendingPeople;
