
import { Pagination } from '@mui/material'; 
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { localDate } from '../../service/validation';
import { getAllPlays, getFilterPlayReport } from '../../service/play';
import { s3Url } from '../../service/FileUpload';



function PlayReport () { 

    const pageSize = 10
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  const [playReport,setPlayReport]=useState([])
  const [search, setSearch] = useState()


  useEffect(() => {
    getAllPlayReportList()
  }, [pagination.from, pagination.to]);

  const getAllPlayReportList = () => {
    const data = {
        limit: 10,
        page: pagination.from,
      }
    getFilterPlayReport(data)
      .then((res) => {
        setPlayReport(res?.data?.result?.playReportList);
        setSearch(res?.data?.result?.playReportList);
        setPagination({ ...pagination, count: res?.data?.result?.playReportCount })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const searchList = (event) => {
    var value = event.target.value.toLowerCase();
    if (value) {
        var searchData = search.filter((x) => {
            return x?.user?.userName.toLowerCase().indexOf(value) !== -1 || !value
        })
        setPlayReport(searchData)
        setPagination({ ...pagination, count: searchData?.length })
    }
    else {
      setPlayReport(search)
        setPagination({ ...pagination, count: search?.length })
    }
}
  

 
	return( 
	<>
    <div className="row">
              <div className="content-page-header">
                <h5>Play Reports</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                  <li>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="form-group-tab">
                          <input type="email" className="form-control" onKeyUp={searchList} placeholder="Search User Name" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="card-table table-responsive overflow-auto">
                    <table className="card-table dataTable">
                        <thead>
                          <tr>
                            <th>Post No.</th>
                            <th>Poster</th>
                            <th>File Type</th>
                            <th>User Name</th>
                            <th>Post Date</th>
                            <th>Reporter Name</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {playReport?.map((data, index) => (
                            <React.Fragment key={index}>
                            <tr>
                              <td rowSpan={data?.report?.length+1} >#{pagination.from + index + 1}</td>
                              <td rowSpan={data?.report?.length+1}>
                                {data?.fileType === 1 ?
                                  <img
                                    className="avatar-sm-post"
                                    src={s3Url+data?.key}
                                    alt="User"
                                  /> : <video src={s3Url+data?.key} type="video/mp4" className="avatar-sm-post">
                                  </video>}
                              </td>
                              <td rowSpan={data?.report?.length+1}>
                                {data?.fileType === 1 ? "Image" : "Video"}
                              </td>
                              <td rowSpan={data?.report?.length+1}>
                                <Link className=" pointer" to={{ pathname: '/PostListView', search: `?id=${data?._id}` }}>
                                  <h6 className="tb-name">
                                    {data?.user?.userName}
                                  </h6>
                                </Link>
                              </td>
                              <td rowSpan={data?.report?.length+1}>
                                {localDate(data?.createdOn)}
                              </td>
                            </tr>
                              {data?.report?.map((x,i)=>
                                <tr key={i}>
                                  <td>{x?.user?.userName}</td>
                                  <td>{x?.type}</td>
                                  <td>{x?.createdOn?localDate(x?.createdOn):'-'}</td>
                                  <td>{x?.description}</td>
                                  </tr>)}
                                  </React.Fragment>
                          ))}
                          {playReport?.length === 0 ?
                            <tr>
                              <td className="form-text text-danger">
                                No data </td>
                            </tr> : null}
                        </tbody>
                      </table>
                    </div>
                    <div className='float-end my-2'>
                      <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  </> 
  ); 
  } 
  export default PlayReport;