import axios from "axios";

// const API = axios.create({baseURL:"http://localhost:4000/api"})
const API = axios.create({baseURL:"https://dev.pixalive.me/api/"})

API.interceptors.request.use(request => {
    const token = localStorage.getItem("token")
    const basicAuth = `Basic ` + btoa("pixalive:DAF87DSFDSFDSA98FSADKJE324KJL32HFD7FDSFB24343J49DSF");
    request.headers.authorization = basicAuth;
    if (token) {
        request.headers.token = `Bearer ${token}`;
    }
    return request
})

API.interceptors.response.use(response => {
    return response
})


export default API;