import axios from './interceptor'

export const getAllStory = (data) => {
    return axios.put("/story/getFilterAdminStory", data);
};

export const getSingleStory = (data) => {
    return axios.get("/story/getSingleStory", { params: { _id: data } });
};


export const saveStory = (data) => {
    return axios.post("/story",data);
  };

  export const updateStory = (data) => {
    return axios.put("/story",data);
  };

  export const deleteStory = (data) => {
    return axios.delete("/story",{params:{_id:data}});
  };