import { useEffect } from "react";
import { useState } from "react";
import { getFilterBidPost } from "../../service/post";
import { Link } from "react-router-dom";
import { localDate } from "../../service/validation";
import { Dialog, DialogContent, DialogTitle, IconButton, Pagination } from "@mui/material";
import { getAllBiddingByPost, updateBiddedUser } from "../../service/bidding";
import { toast } from "react-toastify";
import { s3Url } from "../../service/FileUpload";

function PostBidding() {
    const [post,setPost]=useState()
    const pageSize = 10;
    const [search, setSearch] = useState();
    const [open, setOpen] = useState(false);
    const[inputs,setInputs]=useState();
    const[bidded,setBidded]=useState(false)
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    })
    const data = {
        limit: 10,
        page: pagination.from
    }

    useEffect(() => {
        getFilterPostList()
    }, [pagination.from, pagination.to])

    const getFilterPostList = () => {
        getFilterBidPost(data).then(res => {
            setPost(res?.data?.result?.postList)
            setSearch(res?.data?.result?.postList)
            setPagination({ ...pagination, count: res?.data?.result?.postCount })
        })
            .catch(err => {
                console.log(err);
            })
    }

    const getAllBiddingList = (data) => {
        const postId = {post:data}
        getAllBiddingByPost(postId).then(res => {
            setInputs(res?.data?.result)
        })
            .catch(err => {
                console.log(err);
            })
    }

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize
        setPagination({ ...pagination, from: from, to: to, })
    }

    const searchList = (event) => {
        var value = event.target.value?.toLowerCase();
        if (value) {
            var searchData = search.filter((x) => {
                return x?.user?.phone?.toLowerCase().indexOf(value) !== -1 || !value;
            })
            setPost(searchData)
            setPagination({ ...pagination, count: searchData?.length })
        }
        else {
            setPost(search)
            setPagination({ ...pagination, count: search?.length })
        }
    }

    const openPopup = (data) => {
        setOpen(true);
        getAllBiddingList(data?._id);
        setBidded(data?.isBided);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const updatePostBiddedUser =(data)=> {
        const id = {_id:data?._id}
        updateBiddedUser(id).then(res => {
            closePopup();
            toast.success(res?.data?.message);
            getFilterPostList()
        })
            .catch(err => {
                console.log(err);
            })
    } 


    return (
      <>
      <div className="row">
        <div className="content-page-header mb-20">
          <h6>Post List</h6>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="form-group-tab">
                    <input type="text" className="form-control" onKeyUp={searchList} placeholder="Search Phone" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card-table table-responsive overflow-auto">
            <table id="pixalive-table" className="dataTable">
              <thead>
                <tr>
                  <th>Post No.</th>
                  <th>Poster</th>
                  <th>User Name</th>
                  <th>Phone</th>
                  <th>Post Date</th>
                  <th>IsBided</th>
                  <th>Bided User</th>
                  <th>Bided Coins</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {post?.map((data, index) => (
                  <tr key={index}>
                    <td>#{pagination.from + index + 1}</td>
                    <td className="pointer" onClick={()=>openPopup(data)}>
                        {data?.fileType===1?
                    <img
                          className="avatar-sm-post"
                          src={s3Url+data?.key}
                          alt="User"
                        />:
                      <video src={s3Url+data?.key} type="video/mp4" className="avatar-sm-post">
                      </video>}
                    </td>
                    <td>
                        <h6 className="tb-name">
                          {data?.user?.userName}
                        </h6>
                    </td>
                    <td>{data?.user?.phone}</td>
                    <td>
                      {localDate(data?.createdOn)}
                    </td>
                    <td>{data?.isBided?'Yes':'No'}</td>
                    <td>{data?.bidedUser?.userName??'-'}</td>
                    <td>{data?.bidAmount??0}</td>
                    <td>
                          <Link onClick={()=>openPopup(data)}><i className="far fa-eye me-2"></i></Link>
                    </td>
                  </tr>
                ))}
                {post?.length === 0 ?
                  <tr>
                    <td className="form-text text-danger">
                       No data 
                    </td>
                  </tr> : null}
              </tbody>
            </table>
          </div>
          <div className='float-end my-2'>
            <Pagination count={Math.ceil(pagination.count / pageSize)}
              onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
          </div>
        </div>
      </div>
      <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>
               Post Bidding
                <IconButton className="float-end" onClick={closePopup} >
                    <i className="fa fa-times fa-xs" aria-hidden="true"></i>
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <div className="card-table table-responsive overflow-auto">
             <table className="dataTable">
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Coins</th>
                        {!bidded? <th>Action</th>:null}
                    </tr>
                </thead>
                <tbody>
                    {inputs?.map((data,index)=>
                        <tr key={index}>
                        <td>
                            {data?.isBidded?
                            <strong>{data?.user?.userName}</strong>:
                            <span>{data?.user?.userName}</span>}
                            </td>
                        <td>
                        {data?.isBidded?
                            <strong>{data?.bidCoins}</strong>:
                            <span>{data?.bidCoins}</span>}</td>
                        <td>
                            {!bidded?
                        <button type="Button" onClick={()=>updatePostBiddedUser(data)} className="btn btn-buy">Buy</button>:null}
                        </td>
                    </tr>)}
                </tbody>
             </table></div>
            </DialogContent>
        </Dialog>
      </>
    );
  }
  export default PostBidding;