
import { useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { getSettings, updateSettings } from '../../service/settings';
import { toast } from 'react-toastify';


function Notifications() {

  const [inputs, setInputs] = useState()

  const handleInputs = (event) => {
    const data = event.target.checked === true ? 'yes' : 'no'
    setInputs({ ...inputs, description: data });
  }

  useEffect(() => {
    getSettingsDetails()
  }, [])

  const getSettingsDetails = () => {
    getSettings('notification').then(res => {
      let { title, description } = res?.data?.result
      setInputs({ ...inputs, title, description })
    }).catch(err => {
      console.log(err);
    })
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    updateSettings(inputs)
      .then((res) => {
        toast.success(res?.data?.message);
        getSettingsDetails()
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });

  }


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Notifications</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-4 mt-2">
                <div className="card">
                  <div className="card-body">
                    <div className="widget settings-menu mb-0">
                      <ul>
                        <li className="nav-item">
                          <Link to="/Settings" className="nav-link">
                            <i className="far fa-user"></i>
                            <span>Profile Settings</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Terms" className="nav-link">
                            <i className="far fa-file"></i>
                            <span>Terms and Conditions</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Privacy" className="nav-link">
                            <i className="fas fa-file"></i>
                            <span>Privacy Policy</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Notifications" className="nav-link active">
                            <i className="far fa-bell"></i>
                            <span>Notifications</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Password" className="nav-link">
                            <i className="fas fa-unlock-alt"></i>
                            <span>Change Password</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="card mt-2">
                  <div className="card-header"><div className="card-body">
                    <h5 className="card-title">Notifications</h5>
                  </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row form-group">
                        <label htmlFor="name" className="col-sm-3 col-form-label input-label">Push Notifications</label>
                        <div className="col-sm-9">
                          <div className="status-toggle">
                            <input type="checkbox" checked={inputs?.description==='yes'?true:false}  name='description' onChange={handleInputs} id="switch" />
                            <label htmlFor="switch">Toggle</label>
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button type="submit" className="btn btn-save">Save Changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default Notifications;