
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { useState } from "react";
import {  s3Url } from "../../service/FileUpload";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { getFilterUsers } from "../../service/user";
import { savePlay } from "../../service/play";
import { Storage } from "aws-amplify";

function AddPlays() {
  let initialStateInputs = {
    user: "",
    url: "",
    playType: 1,
    isAdmin: true,
    isActive: true,
    description:"",
    likeCount: 0,
    shareCount: 0,
    viewedCount: 0,
    commentCount: 0,
  };

  let initialStateErrors = {
    user: { required: false },
    url: { required: false },
  };
  const [errors, setErrors] = useState(initialStateErrors);
  const [inputs, setInputs] = useState(initialStateInputs);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getAllUserList();
  }, []);

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      Storage.put(file.name, file).then(res => {
				var url = `${s3Url}public/` + res.key;
				var key = 'public/' + res.key;
        setInputs({ ...inputs, url,key });
			}).catch(err => { console.log(err); })
    }
  };


  const getAllUserList = () => {
    const data = { isAdmin: true };
    getFilterUsers(data)
      .then((res) => {
        const userList = res?.data?.result?.userList;
        setUser(userList);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target?.name]: event.target.value });
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (inputs.url === "") {
      error.url.required = true;
      hashError = true;
    }
    if (inputs.user === "") {
      error.user.required = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      savePlay(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/UploadContent");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="content-page-header">
                  <h5>Add Plays</h5>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">Upload Content</Link></li>
                    <li className="breadcrumb-item active">Add Plays</li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card mt-2">
                    <div className="card-body">
                      <div className="row">
                        <div className=" row col-lg-12 col-md-6 col-sm-12">
                          <div className=" col-lg-6 upload-img form-group">
                            <label>Upload Video</label>
                            <div className="image-upload image-upload-logo">
                              <input
                                type="file"
                                accept="video/*"
                                onChange={handleFileInputs}
                              />
                              <div className="image-uploads im-upload-logo">
                                <video src={(s3Url+inputs?.key)} poster={(!inputs?.url) ? './assets/images/upload.svg' : null} className="avatar-sm-post"></video>
                                <h4>Upload Video</h4>
                              </div>
                            </div>
                            {errors.url.required ? (
                              <span className="form-text text-danger">
                                This field is required.
                              </span>
                            ) : null}
                          </div>
                          {(inputs?.url) ?
                            <div className=" mt-3 ms-5 col-lg-6">
                              <video src={s3Url+inputs?.key} width="300" height="200" controls>
                              </video>
                            </div> : null}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Post User</label>
                            <select
                              defaultValue={"default"}
                              className="form-select"
                              onChange={handleInputs}
                              name="user"
                            >
                              <option value={"default"} disabled hidden>
                                Select Post User
                              </option>
                              {user?.map((data, index) => (
                                <option key={index} value={data?._id}>
                                  {data?.userName}
                                </option>
                              ))}
                            </select>
                            {errors.user.required ? (
                              <span className="form-text text-danger">
                                This field is required.
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Like</label>
                            <input
                              type="text"
                              id="mobile_code"
                              className="form-control"
                              onChange={handleInputs}
                              placeholder="Enter Like"
                              name="likeCount"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Comment</label>
                            <input
                              type="text"
                              className="form-control"
                              name="commentCount"
                              onChange={handleInputs}
                              placeholder="Enter Comment"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Share</label>
                            <input
                              type="text"
                              className="form-control"
                              name="shareCount"
                              onChange={handleInputs}
                              placeholder="Enter Share"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>View</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter View"
                              name="viewedCount"
                              onChange={handleInputs}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Post Type</label>
                            <select
                              className="form-select"
                              onChange={handleInputs}
                              name="playType"
                            >
                              <option value={1}>Public</option>
                              <option value={2}>Private</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Status</label>
                            <select
                              className="form-select"
                              name="isActive"
                              onChange={handleInputs}
                            >
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group notes-form-group">
                            <label>Description</label>
                            <textarea name='description' className="form-control" placeholder="Enter Your Description" onChange={handleInputs}></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="add-customer-btns text-end mb-40">
                        <Link to="/UploadContent" className="btn btn-cancel">
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-save">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddPlays;