import axios from './interceptor'


export const getDashboardDetails = () => {
    return axios.get("/dashboard");
  };

  export const getRecentUserAndPost = () => {
    return axios.get("/dashboard/getRecentUserAndPost");
  };

  export const getChartDetails = () => {
    return axios.get("/dashboard/getChartDetails");
  };