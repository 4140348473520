import axios from './interceptor';


export const getFilterSlider = (data) => {
    return axios.put("/slider/getFilterSlider",data);
  };


  export const saveSlider = (data) => {
    return axios.post("/slider",data);
  };

  export const updateSlider= (data) => {
    return axios.put("/slider",data);
  };

  
  export const deleteSlider = (data) => {
    return axios.delete("/slider",{params:{_id:data}});
  };

  export const getAllSlider = () => {
    return axios.get("/slider");
  };