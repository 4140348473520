
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { useEffect, useState } from 'react';
import { updatePassword } from '../../service/settings';
import { toast } from 'react-toastify';
import { isValidPassword } from '../../service/validation';
import { getUserId } from '../../service/Storage';


function Password() {

  let initialStateInputs = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  }
  let initialStateErrors = {
    password: { required: false, valid: false },
    currentPassword: { required: false },
    confirmPassword: { required: false, valid: false },
  };
  const [errors, setErrors] = useState(initialStateErrors)
  const [inputs, setInputs] = useState(initialStateInputs)
  const [progress, setProgress] = useState(false)

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  }

  useEffect(() => {
    const userId = getUserId()
    setInputs({ ...inputs, _id: userId })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    setProgress(true)
    if (inputs.currentPassword === "") {
      error.currentPassword.required = true;
      hashError = true;
    }
    if (inputs.password === "") {
      error.password.required = true;
      hashError = true;
    }
    if (inputs.password && !isValidPassword(inputs.password)) {
      error.password.valid = true;
      hashError = true;
    }
    if (inputs.confirmPassword === "") {
      error.confirmPassword.required = true;
      hashError = true;
    }
    if ( inputs.confirmPassword && inputs.confirmPassword !== inputs?.password) {
      error.confirmPassword.valid = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      updatePassword(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          setInputs({
            ...inputs,
            currentPassword: "",
            password: "",
            confirmPassword: ""
          })
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }



  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Change Password</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-4 mt-2">
                <div className="card">
                  <div className="card-body">
                    <div className="widget settings-menu mb-0">
                      <ul>
                        <li className="nav-item">
                          <Link to="/Settings" className="nav-link">
                            <i className="far fa-user"></i>
                            <span>Profile Settings</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Terms" className="nav-link">
                            <i className="far fa-file"></i>
                            <span>Terms and Conditions</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Privacy" className="nav-link">
                            <i className="fas fa-file"></i>
                            <span>Privacy Policy</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Notifications" className="nav-link">
                            <i className="far fa-bell"></i>
                            <span>Notifications</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Password" className="nav-link active">
                            <i className="fas fa-unlock-alt"></i>
                            <span>Change Password</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="card mt-2">
                  <div className="card-header"><div className="card-body">
                    <h5 className="card-title">Profile information</h5>
                  </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row form-group">
                        <label htmlFor="current_password" className="col-sm-3 col-form-label input-label">Current Password</label>
                        <div className="col-sm-9">
                          <input type="password" className="form-control" name='currentPassword' value={inputs?.currentPassword} onChange={handleInputs} id="current_password" placeholder="Enter current password" />
                          {errors.currentPassword.required ? <span className="form-text text-danger">
                            This field is required.
                          </span> : null}
                        </div>
                      </div>
                      <div className="row form-group">
                        <label htmlFor="new_password" className="col-sm-3 col-form-label input-label">New Password</label>
                        <div className="col-sm-9">
                          <input type="password" name='password' value={inputs?.password} onChange={handleInputs} className="form-control" id="new_password" placeholder="Enter new password" />
                          {errors.password.required ? <span className="form-text text-danger">
                            This field is required.
                          </span> : null}
                          {errors.password.valid ? (
                            <span className="text-danger form-text">
                              A minimum 8 characters password contains a
                              combination of {''}
                              <strong>uppercase and lowercase letter {''}</strong>
                              and <strong>number</strong>.
                            </span>
                          ) : null}
                          <div className="progress progress-md mt-2">
                            <div className={(inputs?.password && progress) ? ((!errors?.password?.valid && !errors?.password?.required) ? 'progress-bar pro-success' : 'progress-bar pro-warning') : 'progress-bar pro-danger'} aria-valuenow="2" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label htmlFor="confirm_password" className="col-sm-3 col-form-label input-label">Confirm new password</label>
                        <div className="col-sm-9">
                          <div className="mb-3">
                            <input type="password" name='confirmPassword' value={inputs?.confirmPassword} onChange={handleInputs} className="form-control" id="confirm_password" placeholder="Confirm your new password" />
                            {errors.confirmPassword.required ? <span className="form-text text-danger">
                              This field is required.
                            </span> : null}
                            {errors.confirmPassword.valid ? <span className="form-text text-danger">
                              The password and confirm password are not equal.
                            </span> : null}
                          </div>
                          <h5>Password requirements:</h5>
                          <p className="mb-2">Ensure that these requirements are met:</p>
                          <ul className="list-unstyled small">
                            <li>Minimum 8 characters long - the more, the better</li>
                            <li>At least one lowercase character</li>
                            <li>At least one uppercase character</li>
                            <li>At least one number, symbol</li>
                          </ul>
                        </div>
                      </div>
                      <div className="text-end">
                        <button type="submit" className="btn btn-save">Save Changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default Password;