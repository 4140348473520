import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { clearStorage } from '../service/Storage';
import { toast } from 'react-toastify';

function SideBar() {

    var handleNavigation = function () {
        $('#main-wrapper').toggleClass("menu-toggle");

        $(".hamburger").toggleClass("is-active")
    }

    const logout = () => {
        clearStorage();
        toast.success('You have logged out successfully.')
    }


    return (
        <>

            <div className="nav-header">
                <a href="/#" className="brand-logo">
                    <div className="logo-abbr">
                        <img src="../assets/images/logo-icon.svg" className="img-fluid logo" alt="" />
                    </div>
                    <div className="brand-title">
                        <img src="../assets/images/logo-text.svg" className="img-fluid logo" alt="" />
                    </div>

                </a>
                <div className="nav-control" onClick={handleNavigation}>
                    <div className="hamburger">
                        <span className="bar-icons"></span>
                        <span className="bar-icons"></span>
                        <span className="bar-icons"></span>
                        <span className="bar-icons"></span>
                    </div>
                </div>
            </div>
            <div className="deznav">
                <div className="deznav-scroll">
                    <ul className="metismenu">

                        <li className="menu-title">Main</li>
                        <li><Link to='/Dashboard' aria-expanded="false">
                            <i className="fe fe-home"></i>
                            <span className="nav-text">Dashboard</span>
                        </Link>
                        </li>
                        <li><Link to="/UserList" aria-expanded="false"><i className="fe fe-users"></i> <span className="nav-text">User Management</span></Link></li>
                        <li>
                            <Link to="#submenu" data-bs-toggle="collapse" className="has-arrow" aria-expanded="false">
                                <i className="fe fe-sidebar"></i>
                                <span className="nav-text">Post Management</span>
                            </Link>
                            <ul id='submenu' className='collapse' aria-expanded="false">
                                <li><Link to="/PostList">Posts </Link></li>
                                <li><Link to="/Plays">Plays </Link></li>
                                <li><Link to="/Stories">Stories </Link></li>
                            </ul>
                        </li>

                        <li className="menu-title"><span>General</span></li>
                        <li><Link to="/CategoryList" aria-expanded="false"><i className="fe fe-grid"></i> <span className="nav-text">Category Management</span></Link></li>
                        <li>
                            <Link to="#submenu1" data-bs-toggle="collapse" className="has-arrow" aria-expanded="false">
                                <i className="fe fe-award"></i>
                                <span className="nav-text">Trending</span>
                            </Link>
                            <ul id='submenu1' className='collapse' aria-expanded="false">
                                <li><Link to="/TrendingPeople">TrendingPeople </Link></li>
                                <li><Link to="/Slider">Slider </Link></li>
                            </ul>
                        </li>
                        <li><Link to="/UploadContent" aria-expanded="false"><i className="fe fe-file-text"></i>  <span className="nav-text">Upload Content</span></Link></li>
                        {/* <li className="menu-title"><span>Reports</span></li> */}
                        <li><Link to="/Bidding" aria-expanded="false"><i className="fe fe-dollar-sign"></i>  <span className="nav-text">Bidding Management</span></Link></li>
                        <li className="menu-title" aria-expanded="false"> <span className="nav-text">Settings</span></li>
                        <li><Link to="/Settings" aria-expanded="false"><i className="fe fe-settings"></i>  <span className="nav-text">Settings</span></Link></li>
                        <li><Link to="/Reports" aria-expanded="false"><i className="fe fe-alert-triangle"></i>  <span className="nav-text">Reports</span></Link></li>
                        <li><Link to="/PushNotification" aria-expanded="false"><i className="fe fe-bell"></i>  <span className="nav-text">Push Notification</span></Link></li>
                        <li><Link onClick={logout} to='/' ><i className="fe fe-power"></i>  <span className="nav-text">Logout</span></Link></li>
                    </ul>
                </div>
            </div>


        </>
    );
}
export default SideBar;