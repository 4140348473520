import { Dialog, DialogContent, DialogTitle, IconButton, Pagination } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getFilterCoins, updateCoins } from "../../service/coins";
import { toast } from "react-toastify";


function CoinsManagement() {
    let initialStateErrors = {
        coins: { required: false },
    };
    const pageSize = 10;
    const [search, setSearch] = useState();
    const [open, setOpen] = useState(false);
    const [inputs, setInputs] = useState();
    const [errors, setErrors] = useState(initialStateErrors);
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    })
    const [coins, setCoins] = useState()
    const data = {
        limit: 10,
        page: pagination.from
    }

    useEffect(() => {
        getFilterCoinsList()
    }, [pagination.from, pagination.to])

    const getFilterCoinsList = () => {
        getFilterCoins(data).then(res => {
            setCoins(res?.data?.result?.coinsList)
            setSearch(res?.data?.result?.coinsList)
            setPagination({ ...pagination, count: res?.data?.result?.coinsCount })
        })
            .catch(err => {
                console.log(err);
            })
    }

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize
        setPagination({ ...pagination, from: from, to: to, })
    }

    const searchList = (event) => {
        var value = event.target.value.toLowerCase();
        if (value) {
            var searchData = search.filter((x) => {
                return x?.title.toLowerCase().indexOf(value) !== -1 || !value;
            })
            setCoins(searchData)
            setPagination({ ...pagination, count: searchData?.length })
        }
        else {
            setCoins(search)
            setPagination({ ...pagination, count: search?.length })
        }
    }

    const openPopup = (data) => {
        setInputs(data)
        setOpen(true);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const handleInputs = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let error = initialStateErrors;
        var hashError = false;
        if (inputs.coins === "") {
            error.coins.required = true;
            hashError = true;
        }
        setErrors({ ...error });
        if (!hashError) {
            updateCoins(inputs)
                .then((res) => {
                    toast.success(res?.data?.message);
                    closePopup();
                    getFilterCoinsList()
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message);
                });
        }
    }


return (
    <>
        <div className="row">
            <div className="content-page-header mb-20">
                <h6>Coins Management</h6>
                <div className="list-btn">
                    <ul className="filter-list">
                        <li>
                            <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="form-group-tab">
                                    <input type="email" className="form-control" onKeyUp={searchList} placeholder="Search Title" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="col-xl-12">
                <div className="card-table table-responsive overflow-auto">
                    <table id="pixalive-table" className="dataTable">
                        <thead>
                            <tr>
                                <th>S.NO</th>
                                <th>Title</th>
                                <th>Coins</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coins?.map((data, index) => (
                                <tr key={index}>
                                    <td>#   {pagination.from + index + 1}</td>
                                    <td>
                                        {(data?.title)}
                                    </td>
                                    <td>{data?.coins}</td>
                                    <td>
                                        <Link onClick={() => openPopup(data)} ><i className="far fa-edit me-2"></i></Link>
                                    </td>
                                </tr>
                            ))}
                            {coins?.length === 0 ?
                                <tr>
                                    <td className="form-text text-danger">
                                        No data
                                    </td>
                                </tr> : null}
                        </tbody>
                    </table>
                </div>
                <div className='float-end my-2'>
                    <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                </div>
            </div>
        </div>

        <Dialog open={open} fullWidth maxWidth="sm">
            <DialogTitle>
                Edit Coins
                <IconButton className="float-end" onClick={closePopup} >
                    <i className="fa fa-times fa-xs" aria-hidden="true"></i>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <div className="from-group mb-3">
                        <label htmlFor="Title" className="form-label">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="Title"
                            name="title"
                            readOnly
                            value={inputs?.title}
                            placeholder="Enter Title"
                        />
                    </div>
                    <div className="from-group mb-3">
                        <label htmlFor="Coins" className="form-label">
                            Coins
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="Coins"
                            name="coins"
                            value={inputs?.coins}
                            placeholder="Enter Coins"
                            onChange={handleInputs}
                        />
                        {errors.coins.required ? <span className="form-text text-danger">
                              This field is required.
                            </span> : null}
                    </div>
                    <div><button type="button" className="btn btn-cancel  float-end" onClick={closePopup}>Cancel</button>
                        <button type="submit" className="btn btn-save float-end mx-2">Update</button></div>
                </form>
            </DialogContent>
        </Dialog>
    </>
);
}
export default CoinsManagement;