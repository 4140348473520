import axios from './interceptor'

export const sendNotification = (data) => {
    return axios.post("/notification/sendPushNotification", data);
};

export const getFilterNotification = (data) => {
    return axios.put("/notification/getFilterNotification",data);
  };

  export const deleteNotification = (data) => {
    return axios.delete("/notification",{params:{_id:data}});
  };