import axios from './interceptor';

export const getAllBiddingByPost = (data) => {
    return axios.get("/bidding/getAllBiddingByPost",{params:data});
  };

  export const getAllBiddingByPlay = (data) => {
    return axios.get("/bidding/getAllBiddingByPlay",{params:data});
  };

  export const updateBiddedUser = (data)=>{
    return axios.put('/bidding/updateBiddedUser',data)
  }