import axios from './interceptor';


export const getFilterCategory = (data) => {
  return axios.put("/category/getFilterAdminCategory", data);
};


export const saveCategory = (data) => {
  return axios.post("/category", data);
};

export const updateCategory = (data) => {
  return axios.put("/category", data);
};


export const deleteCategory = (data) => {
  return axios.delete("/category", { params: { _id: data } });
};

export const getAllCategory = () => {
  return axios.get("/category");
};

export const getFollowCategoryDetails = () => {
  return axios.get("/category/getFollowCategoryDetails");
};

export const getCategoryBasedDetails = (data) => {
  return axios.get("/category/getCategoryBasedDetails", { params: { _id: data } });
};