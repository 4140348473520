
import { useEffect } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { useState } from 'react';
import { deleteUser, getAllUsers, getFilterUsers, updateUserStatus } from '../../service/user';
import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material';
import { templatePdf } from '../../service/pdfmake';
import { ExportCsvService } from '../../service/excel';
import { localDate } from '../../service/validation';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';
import { s3Url } from '../../service/FileUpload';

function UserList() {
  const initialStateInputs = {
    userId: "",
    userName: "",
    phone: ""
  }
  const pageSize = 10;
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })
  const [users, setUsers] = useState()
  const [inputs, setInputs] = useState(initialStateInputs)
  const [filter, setFilter] = useState(false)
  const [open, setOpen] = useState(false)
  const [userList, setUserList] = useState()
  const [remove, setRemove] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [status, setStatus] = useState(false)
  const [active, setActive] = useState('')
  const [activeId, setActiveId] = useState('')
  const [search, setSearch] = useState([])

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openStatus = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveId(id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = {
    isAdmin: false
  }

  useEffect(()=>{
    getFilterUserList();
  },[])

  const getFilterUserList = () => {
    getFilterUsers(data).then(res => {
      setUsers(res?.data?.result?.userList)
      setSearch(res?.data?.result?.userList)
      setPagination({ ...pagination, count: res?.data?.result?.userCount })
    })
      .catch(err => {
        console.log(err);
      })
  }

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const getAllUserList = () => {
    getAllUsers().then(res => {
      console.log('uu', res);
      setUserList(res?.data?.result)
    }).catch(err => {
      console.log(err);
    })
  }

  const openFilterPopup = () => {
    getAllUserList()
    setOpen(true)
  }

  const closeFilterPopup = () => {
    setOpen(false)
  }

  const filterUserList = (event) => {
    setFilter(true);
    event.preventDefault()
    var data = {
      phone: inputs?.phone,
      userName: inputs?.userName,
      userId: inputs?.userId,
      limit: 10,
      page: pagination.from,
      isAdmin: false
    }
    getFilterUsers(data)
      .then((res) => {
        setUsers(res?.data?.result?.userList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.userCount,
        });
        closeFilterPopup()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const resetFilter = () => {
    setFilter(false)
    setInputs(initialStateInputs)
    getFilterUserList()
  }

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };


  const pdfDownload = () => {
    var data = {
      isAdmin: false
    }
    getFilterUsers(data).then(res => {
      var result = res?.data?.result?.userList
      var tablebody = [];
      tablebody.push([
        {
          text: 'S.NO', fontSize: 11, alignment: 'center', margin: [5, 5], bold: true
        },
        {
          text: 'User Id', fontSize: 11, alignment: 'center', margin: [40, 5], bold: true
        },
        {
          text: 'User Name', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Phone No', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Registration Date', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Gender', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Account Info', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Status', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        }
      ])
      result.forEach((element, index) => {
        tablebody.push([
          {
            text: index + 1, fontSize: 10, alignment: 'left', margin: [5, 3], border: [true, false, true, true]
          },
          {
            text: element?.userId, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.userName?.toUpperCase(), fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.phone, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: localDate(element?.createdOn) ?? '-', fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.gender ?? '-', fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: (element?.isPrivate) ? 'Private' : 'Public', fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: (element?.isActive) ? 'Active' : 'Inactive', fontSize: 10, alignment: 'left', margin: [5, 3]
          }
        ])
      });
      templatePdf("User List", tablebody, 'landscape')
    }).catch(err => { console.log(err); })
  }

  const exportCsv = () => {
    var data = {
      isAdmin: false
    }
    getFilterUsers(data).then(res => {
      var result = res?.data?.result?.userList
      let list = [];
      result?.forEach(res => {
        list.push({
          userId: res?.userId, userName: res?.userName?.toUpperCase() ?? '-', phone: res?.phone ?? '-', registrationDate: localDate(res?.createdOn) ?? '-',
          gender: res?.gender ?? '-', isPrivate: (res?.isPrivate) ? 'Private' : 'Public', isActive: (res?.isActive) ? 'Active' : 'Inactive'
        })
      })
      let header1 = ['userId', 'userName', 'phone', 'registrationDate', 'gender', 'isPrivate', 'isActive'];
      let header2 = ['User Id', 'User Name', 'Phone No', 'Registration Date', 'Gender', 'Account Info', 'Status'];
      ExportCsvService.downloadCsv(list, 'userlist', 'User List', header1, header2);
    }).catch(err => { console.log(err); })
  }

  const openStatusPopup = (status) => {
    setStatus(true)
    setActive(status);
  }

  const closeStatusPopup = () => {
    setStatus(false)
  }

  const handleUserStatus = () => {
    const data = { _id: activeId, isActive: active }
    updateUserStatus(data).then(res => {
      toast.success(res?.data?.message);
      getFilterUserList()
      closeStatusPopup()
    }).catch(err => console.log(err))
  }


  const openPopup = (data) => {
    setRemove(true);
    setDeleteId(data)
  };

  const closePopup = () => {
    setRemove(false);
  };

  const deleteUserData = () => {
    deleteUser(deleteId).then(res => {
      toast.success(res?.data?.message);
      closePopup();
      getFilterUserList()
    }).catch((err) => {
      console.log(err);
    });
  }

  const searchList = (event) => {
    var value = event.target.value.toLowerCase();
    if (value) {
        var searchData = search.filter((x) => {
            return x?.userName.toLowerCase().indexOf(value) !== -1 ||x?.phone.toLowerCase().indexOf(value) !== -1 ||!value;
        })
        setUsers(searchData)
        setPagination({ ...pagination, count: searchData?.length })
    }
    else {
        setUsers(search)
        setPagination({ ...pagination, count: search?.length })
    }
}


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header mb-20">
                <h5>User Management</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                  <li>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <div className="form-group-tab">
                                        <input type="email" className="form-control" placeholder="Search User Name or Phone No" onKeyUp={searchList} />
                                    </div>
                                </div>
                            </li>
                    <li>
                      <Link className="btn-filters" onClick={pdfDownload} >
                        <span>
                          <i className="fa fa-file-pdf" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={exportCsv} >
                        <span>
                          <i className="fa fa-file-excel" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={openFilterPopup}>
                        <span>
                          <i className="fe fe-filter"></i>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="card-table table-responsive overflow-auto">
                      <table id="pixalive-table" className="dataTable">
                        <thead>
                          <tr>
                            <th>User No.</th>
                            <th>User Id</th>
                            <th>Full Name</th>
                            <th>User Name</th>
                            <th>Phone No</th>
                            <th>Coins</th>
                            <th>Email Id</th>
                            <th>Registration Date</th>
                            <th>Gender</th>
                            <th>Account Info</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.slice(pagination?.from,pagination?.to)?.map((data, index) => {
                            return (<tr key={index}>
                              <td>#{pagination.from + index + 1}</td>
                              <td>{data?.userId}</td>
                              <td>
                                <h2 className="table-avatar">
                                  <div className="avatar avatar-md me-2">
                                    <img className="avatar-img rounded-circle" src={s3Url+data?.key} alt="User" /></div>
                                  <span>{data?.name}</span>
                                </h2>
                              </td>
                              <td>
                                <Link to={{ pathname: '/ViewUser', search: `?id=${data?._id}` }} className="tb-name">{data?.userName}</Link>
                              </td>
                              <td>{data?.phone}</td>
                              <td>{data?.coins}</td>
                              <td>{data?.email}</td>
                              <td>{localDate(data?.createdOn)}</td>
                              <td>{data?.gender}</td>
                              <td>{data?.isPrivate ? 'Private' : 'Public'}</td>
                              <td>
                                <Button
                                  id="fade-button"
                                  aria-controls={openStatus ? 'fade-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openStatus ? 'true' : undefined}
                                  onClick={(event) => handleClick(event, data?._id)} size='small'
                                  style={{ textTransform: 'none' }}
                                  variant="contained" color={data?.isActive === true ? "success" : 'error'}
                                >
                                  {data?.isActive === true ? 'Active' : 'Inactive'}
                                </Button>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={openStatus}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(true)} >Active</Link></MenuItem>
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(false)}>Inactive</Link></MenuItem>
                                </Menu>
                              </td>
                              <td>
                                <Link className="dropdown-item" onClick={() => { openPopup(data?._id) }} ><i className="far fa-trash-alt me-2"></i></Link>
                              </td>
                            </tr>)
                          })}
                          {users?.length === 0 ?
                            <tr>
                              <td className="form-text text-danger">
                                No data </td>
                            </tr> : null}
                        </tbody>
                      </table>
                    </div>
                    <div className='float-end my-2'>
                      <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Filter Users
          <IconButton className="float-end" onClick={closeFilterPopup} >
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className="from-group mb-3">
              <label htmlFor="userid" className="form-label">
                User Id
              </label>
              <input
                type="text"
                className="form-control"
                id="userid"
                name="userId"
                value={inputs?.userId}
                onChange={handleInputs}
                placeholder="Enter User Id"
              />
            </div>
            <div className="form-group">
              <label>User Name</label>
              <select className="form-select" value={inputs?.userName ?? ''} onChange={handleInputs} name='userName'>
                <option value={""} disabled hidden >Select User Name</option>
                {userList?.map((data, index) =>
                  <option key={index} value={data?._id}>{data?.userName}</option>)}
              </select>
            </div>
            <div className="from-group mb-3">
              <label htmlFor="phoneNo" className="form-label">
                Phone No
              </label>
              <input
                type="text"
                className="form-control"
                id="phoneNO"
                name="phone"
                value={inputs?.phone}
                onChange={handleInputs}
                placeholder="Enter Phone No"
              />
            </div>
            <div><button type="button" className="btn btn-cancel  float-end" onClick={resetFilter}>Reset</button>
              <button type="submit" onClick={filterUserList} className="btn btn-save float-end mx-2">Apply</button></div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={status}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to {active === true ? 'active' : 'inactive'} <br /> the selected User ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={handleUserStatus}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closeStatusPopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={remove}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to Delete <br /> the selected User ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={deleteUserData}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closePopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default UserList;