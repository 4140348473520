
import Header from '../../components/Header'; 
import SideBar from '../../components/SideBar'; 
import PlayReport from './PlayReport';
import PostReport from './PostReport';



function Reports () { 

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Reports</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-pix" role="tablist">
                      <li className="nav-item" role="presentation"><a className="nav-link active" href="#userlist" data-bs-toggle="tab" aria-selected="true" role="tab">Post Reports</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#imagelist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Play Reports</a></li>
                    </ul>
                    <div className="tab-content tab-content-pix">
                      <div className="tab-pane active show" id="userlist" role="tabpanel">
                        <PostReport />
                      </div>
                      <div className="tab-pane" id="imagelist" role="tabpanel">
                        <PlayReport/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  </>
  );
  } 
  export default Reports;