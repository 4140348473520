import CryptoJS from "crypto-js";
import { Navigate } from "react-router-dom";


export const saveToken =  (data) => {
  localStorage.setItem("token", data?.token); 
  localStorage.setItem('userId', CryptoJS.AES.encrypt((data?._id),'pixalive').toString())
};


export const getToken = () => {
  return localStorage.getItem("token");
};

export const getUserId = () => {
  const userId =  localStorage.getItem('userId')
  return CryptoJS.AES.decrypt(userId,'pixalive').toString(CryptoJS.enc.Utf8)
};


export const clearStorage = () => {
   localStorage.clear()
};
