import axios from './interceptor';


export const getAllPosts = (data) => {
    return axios.put("/post/getFilterAdminPost",data);
  };

  export const getSinglePost = (data) => {
    return axios.get("/post/getSinglePost",{params:{_id:data}});
  };

  export const savePost = (data) => {
    return axios.post("/post",data);
  };

  export const updatePost = (data) => {
    return axios.put("/post",data);
  };

  export const deletePost = (data) => {
    return axios.delete("/post",{params:{_id:data}});
  };

  export const getFilterBidPost = (data) => {
    return axios.put("/post/getFilterBidPost",data);
  };

  export const getFilterPostReport = (data) => {
    return axios.put("/post/getFilterPostReport",data);
  };

  export const updatePostStatus = (data) => {
    return axios.put("/post/updatePostActiveStatus",data);
  };