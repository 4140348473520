import { useEffect, useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { deleteCategory, getFilterCategory, getFollowCategoryDetails, updateCategory } from "../../service/category";
import { toast } from "react-toastify";
import { saveCategory } from "../../service/category";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Pagination,
} from "@mui/material";
import { uploadFile } from "../../service/FileUpload";
import { Link } from "react-router-dom";

function CategoryList() {

	let initialStateInputs = {
		category: "",
		image: "",
	}

	let initialStateErrors = {
		category: { required: false },
		image: { required: false },
	};
	const [errors, setErrors] = useState(initialStateErrors);
	const [category, setCategory] = useState();
	const [open, setOpen] = useState(false);
	const [remove, setRemove] = useState(false);
	const [filter, setFilter] = useState(false)
	const [inputs, setInputs] = useState(initialStateInputs);
	const pageSize = 8;
	const [count, setCount] = useState(0)

	const [pagination, setPagination] = useState({
		count: 0,
		from: 0,
		to: pageSize,
	});

	const data = {
		limit: 8,
		page: pagination.from,
	};

	useEffect(() => {
		getFollowingCategoryDetails()
	}, [])


	useEffect(() => {
		if (count) {
			getFilterCategoryList();
		}
	}, [pagination.from, pagination.to,count]);


	const getFollowingCategoryDetails = () => {
		getFollowCategoryDetails().then(res => {
			setCount(res?.data?.result?.user)
		}).catch(err => console.log(err))
	}

	const getFilterCategoryList = () => {
		getFilterCategory(data)
			.then((res) => {
				const result = res?.data?.result?.categoryList
				result?.forEach(x => {
					count?.forEach(y => {
						if (y._id === x._id) {
							x.count = y.followers
						}
					})
				});
				setCategory(result);
				setPagination({
					...pagination,
					count: res?.data?.result?.categoryCount,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handlePageChange = (event, page) => {
		const from = (page - 1) * pageSize;
		const to = (page - 1) * pageSize + pageSize;
		setPagination({ ...pagination, from: from, to: to });
	};

	const openPopup = () => {
		setInputs(initialStateInputs)
		setOpen(true);
	};
	const closePopup = () => {
		setOpen(false);
	};

	const openDeletePopup = (data) => {
		setRemove(true)
		setInputs({ ...inputs, _id: data })
	}

	const closeDeletePopup = () => {
		setRemove(false)
	}

	const openFilterPopup = () => {
		setFilter(true)
	}

	const closeFilterPopup = () => {
		setFilter(false)
	}


	const resetForm = () => {
		setInputs(initialStateInputs)
	}

	const handleInputs = (event) => {
		setInputs({ ...inputs, [event.target.name]: event.target.value });
	};

	const handleFileInputs = (event) => {
		const file = event.target.files[0];
		const fileName = file?.name;
		const folder = 'category/'
		if (file) {
			uploadFile(file, folder).then(res => {
				const imageUrl = res?.Location
				setInputs({ ...inputs, image: imageUrl, fileName: fileName })
			}).catch(err => { console.log(err); })
		}

	};

	const handleSubmit = (event) => {
		event.preventDefault();
		let error = initialStateErrors;
		var hashError = false;
		if (inputs.category === "") {
			error.category.required = true;
			hashError = true;
		}
		if (inputs.image === "") {
			error.image.required = true;
			hashError = true;
		}
		setErrors({ ...error });
		if (!hashError) {
			if (inputs._id) {
				updateCategory(inputs)
					.then((res) => {
						toast.success(res?.data?.message);
						closePopup();
						getFilterCategoryList()
					})
					.catch((err) => {
						console.log(err);
						toast.error(err?.response?.data?.message);
					});
			}
			else {
				saveCategory(inputs)
					.then((res) => {
						toast.success(res?.data?.message);
						closePopup();
						getFilterCategoryList()
					})
					.catch((err) => {
						toast.error(err?.response?.data?.message);
					});
			}
		}
	};

	const updateCategoryDialog = (data) => {
		const { category, fileName, _id, image } = data
		openPopup()
		setInputs({ ...inputs, category, fileName, _id, image })
	}

	const filterCategory = (event) => {
		event.preventDefault()
		var data = {
			category: inputs.category
		}
		getFilterCategory(data)
			.then((res) => {
				setCategory(res?.data?.result?.categoryList);
				setPagination({
					...pagination,
					count: res?.data?.result?.categoryCount,
				});
				closeFilterPopup()
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const resetFilter = () => {
		setInputs(initialStateInputs)
		getFilterCategoryList()
	}

	const deleteCategoryData = () => {
		deleteCategory(inputs._id).then(res => {
			toast.success(res?.data?.message);
			closeDeletePopup();
			getFilterCategoryList()
		}).catch((err) => {
			toast.error(err?.response?.data?.message);
		});
	}

	return (
		<>
			<div id="main-wrapper">
				<Header />
				<SideBar />

				<div className="content-body">
					<div className="content container-fluid">
						<div className="row">
							<div className="content-page-header mb-20">
								<h5>Category Management</h5>
								<div className="list-btn">
									<ul className="filter-list">
										<li>
											<Link className="btn-filters" onClick={openFilterPopup}>
												<span>
													<i className="fe fe-filter" ></i>
												</span>
											</Link>
										</li>
										<li>
											<button
												className="btn btn-pix-primary "
												onClick={openPopup}
											>
												<i
													className="fa fa-plus-circle me-2"
													aria-hidden="true"
												></i>
												Add Category{" "}
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="card mt-2">
								<div className="card-body">
									<div className="row">
										{category?.map((data, index) => (
											<div key={index} className="col-lg-3 col-md-4 col-sm-4">
												<div className="group-box">
													<figure>
														<img alt="" src={data?.image} />
													</figure>
													<div>
														<Link title="" className="text-capitalize" state={data?.category} to={{ pathname: '/CategoryView', search: `?id=${data?._id}`, }}  >
															{data?.category}</Link>
														<Link className="fa fa-edit fa-xs ms-2" onClick={() => updateCategoryDialog(data)} ></Link>
														<Link className="fa fa-trash-alt fa-xs ms-2" onClick={() => openDeletePopup(data._id)}></Link>
													</div>
													<span>{data?.count} Members</span>
												</div>
											</div>
										))}
									</div>
									{category?.length === 0 ?
										<div>
											<span className="form-text text-danger">
												No data </span>
										</div> : null}
									<div className="float-end my-2">
										<Pagination
											count={Math.ceil(pagination.count / pageSize)}
											onChange={handlePageChange}
											variant="outlined"
											shape="rounded"
											color="primary"

										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog open={open} fullWidth maxWidth="sm">
				<DialogTitle>
					{(!inputs?._id) ? 'Add Category' : 'Update Category'}
					<IconButton className="float-end" onClick={closePopup} >
						<i className="fa fa-times fa-xs" aria-hidden="true"></i>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit}>
						<div className="from-group mb-3">
							<label htmlFor="categoryName" className="form-label">
								Category Name <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								id="categoryName"
								name="category"
								value={inputs.category}
								onChange={handleInputs}
								placeholder="Enter Category"
							/>
							{errors.category.required ? <span className="form-text text-danger">
								This field is required.
							</span> : null}
						</div>
						<div className="mb-3">
							<label htmlFor="file-input" className="form-label">
								Select Image <span className="text-danger">*</span>
							</label>
							<div className="input-group">
								<label className='form-control' style={{ color: (!inputs.fileName) ? "rgb(194 200 206)" : "black" }} htmlFor='file-input'>
									{inputs.fileName ? inputs?.fileName : 'Choose File'}
								</label>
								<input
									type="file" onChange={handleFileInputs}
									name="image"
									id="file-input" style={{ width: '0' }} />
								<div className='input-group-pend'>
									<label className='input-group-text form-control' htmlFor='file-input'>Browse </label>
								</div>
							</div>
							{errors.image.required ? <span className="form-text text-danger">
								This field is required.
							</span> : null}
						</div>
						{!inputs._id ?
							<div><button type="button" onClick={resetForm} className="btn btn-cancel float-end">Reset</button>
								<button type="submit" className="btn btn-save float-end mx-2">Save</button></div> :
							<div><button type="button" onClick={closePopup} className="btn btn-cancel float-end">Cancel</button>
								<button type="submit" className="btn btn-save float-end mx-2">Update</button></div>}
					</form>
				</DialogContent>
			</Dialog>

			<Dialog open={remove}>
				<DialogContent>
					<div className="text-center m-4">
						<h5 className="mb-4">Are you sure you want to Delete <br /> the selected Category ?</h5>
						<button type="button" className="btn btn-save mx-3" onClick={deleteCategoryData}>Yes</button>
						<button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
					</div>
				</DialogContent>
			</Dialog>

			<Dialog open={filter} fullWidth maxWidth="sm">
				<DialogTitle>
					Filter Category
					<IconButton className="float-end" onClick={closeFilterPopup} >
						<i className="fa fa-times fa-xs" aria-hidden="true"></i>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form>
						<div className="from-group mb-3">
							<label htmlFor="categoryName" className="form-label">
								Category Name
							</label>
							<input
								type="text"
								className="form-control"
								id="categoryName"
								name="category"
								value={inputs.category}
								onChange={handleInputs}
								placeholder="Enter Category"
							/>
						</div>
						<div><button type="button" className="btn btn-cancel  float-end" onClick={resetFilter}>Reset</button>
							<button type="submit" onClick={filterCategory} className="btn btn-save float-end mx-2">Apply</button></div>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default CategoryList;
