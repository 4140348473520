
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { getSingleUser, updateUser } from '../../service/user';
import { useState } from 'react';
import { useEffect } from 'react';
import { s3Url } from '../../service/FileUpload';
import { isValidEmail, isValidPhone } from '../../service/validation';
import { toast } from 'react-toastify';
import { Storage } from 'aws-amplify';

function EditUser() {
  let initialStateErrors = {
    userName: { required: false },
    name: { required: false },
    phone: { required: false, valid: false },
    email: { valid: false }
  };
  let initialStateInputs = {
    _id: "",
    name: "",
    userName: "",
    dob: "",
    phone: "",
    email: "",
    gender: "Male",
    isAdmin: true,
    isActive: true,
    isPrivate: false,
    aboutMe: "",
    key:"empty_profile.png",
    imageUrl: 'https://s3.ap-south-1.amazonaws.com/pixalive.me/empty_profile.png',
  }
  const location = useLocation();
  const navigate = useNavigate();
  const userId = new URLSearchParams(location.search).get('id');
  const [user, setUser] = useState(initialStateInputs)
  const [errors, setErrors] = useState(initialStateErrors);
  useEffect(() => {
    getUserDetail()
  }, [])

  const getUserDetail = () => {
    getSingleUser(userId).then(res => {
      const user = res?.data?.result
      setUser(user)
    }).catch(err => {
      console.log(err);
    })
  }

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      Storage.put(file.name, file).then(res => {
        var url = `${s3Url}public/` + res.key;
        var key = 'public/' + res.key;
        setUser({ ...user, imageUrl: url, key })
      }).catch(err => { console.log(err) })
    }
  };


  const handleInputs = (event) => {
    setUser({ ...user, [event.target?.name]: event.target.value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (user.name === "") {
      error.name.required = true;
      hashError = true;
    }
    if (user.userName === "") {
      error.userName.required = true;
      hashError = true;
    }
    if (!isValidPhone(user.phone)) {
      error.phone.valid = true
      hashError = true;
    }
    if (!isValidEmail(user.email)) {
      error.email.valid = true
      hashError = true;
    }
    if (user.phone === "") {
      error.phone.required = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      updateUser(user)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/UploadContent")
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }


  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header page-header mb-20">
                <div className="col">
                  <h5>Edit User</h5>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">User List</Link></li>
                    <li className="breadcrumb-item active">Edit User</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit}>
                        <div className="card-body">
                          <div className="form-group-item">
                            {/* <h5 className="form-title">Basic Details</h5> */}
                            <div className="row">
                              <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="upload-img">
                                  <label>Upload Profile</label>
                                  <div>
                                    <img className="rounded-circle avatar-lg img-thumbnail" alt="" src={s3Url+user?.key} />
                                  </div>
                                  <div className="p-image">
                                    <label htmlFor="file-input"><i className="fa fa-camera pointer" aria-hidden="true"></i></label>
                                    <input name='imageUrl' id="file-input" type="file" style={{ width: '0' }} onChange={handleFileInputs} accept="image/*" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Full Name</label>
                                  <input type="text" className="form-control" name='name' onChange={handleInputs} value={user?.name} />
                                  {errors.name.required ? <span className="form-text text-danger">
                                    This field is required.
                                  </span> : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>User Name</label>
                                  <input type="text" className="form-control" name='userName' onChange={handleInputs} value={user?.userName} />
                                  {errors.userName.required ? <span className="form-text text-danger">
                                    This field is required.
                                  </span> : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Phone No</label>
                                  <input type="text" id="mobile_code" className="form-control" onChange={handleInputs} value={user?.phone} name="phone" />
                                  {errors.phone.required ? (
                                    <span className="text-danger form-text">
                                      This field is required.
                                    </span>
                                  ) : errors.phone.valid ? (
                                    <span className="text-danger form-text">
                                      Enter valid Phone Number.
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Email Id</label>
                                  <input type="text" className="form-control" onChange={handleInputs} name='email' value={user?.email} />
                                  {errors.email.required ? (
                                    <span className="text-danger form-text">
                                      This field is required.
                                    </span>
                                  ) : errors.email.valid ? (
                                    <span className="text-danger form-text">
                                      Enter valid Email Id.
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>DOB(Date of Birth)</label>
                                  <input type="date" className="form-control" name='dob' onChange={handleInputs} value={user?.dob} />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Gender</label>
                                  <select className="form-select" onChange={handleInputs} value={user?.gender} name='gender'>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Account Info</label>
                                  <select className="form-select" onChange={handleInputs} value={user?.isPrivate} name='isPrivate'>
                                    <option value={false}>Public</option>
                                    <option value={true}>Private</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                  <label>Status</label>
                                  <select className="form-select" onChange={handleInputs} value={user?.isActive} name='isActive'>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="form-group notes-form-group">
                                  <label>Bio</label>
                                  <textarea className="form-control" onChange={handleInputs} value={user?.aboutMe} name='aboutMe' placeholder="Enter Your Description"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="add-customer-btns text-end">
                            <Link to="/UploadContent" className="btn btn-cancel">Cancel</Link>
                            <button type='submit' className="btn btn-save">Update</button>
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default EditUser;