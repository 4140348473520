
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import UserDetails from './UserDetails';
import PostDetails from './PostDetails';
import { Link, useLocation } from 'react-router-dom';
import { getCategoryBasedDetails } from '../../service/category';
import { useEffect } from 'react';
import { useState } from 'react';

function CategoryView() {
    const location = useLocation()
    const id = new URLSearchParams(location.search).get('id')
    const categoryName = location.state
    const [category, setCategory] = useState({})

    useEffect(() => {
        getCategoryDetails()
    }, [])

    const getCategoryDetails = () => {
        getCategoryBasedDetails(id).then(res => {
            const result = res?.data?.result
            result.userCount = result?.userList?.length
            result.postCount = result?.postList?.length
            setCategory(result)
        }).catch(err => console.log(err))
    }

    return (
        <>
            <div id="main-wrapper">
                <Header />
                <SideBar />
                <div className="content-body">
                    <div className="content container-fluid">
                        <div className="row">
                            <div className="content-page-header page-header mb-20">
                                <div className="col">
                                    <h5>{categoryName} Category</h5>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/CategoryList">Category List</Link></li>
                                        <li className="breadcrumb-item active">Category Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card mt-2">
                                    <div className='d-flex float-end me-4 mt-3'>
                                        <div className='mx-5 text-center'>
                                            <h5>User Counts</h5>
                                            <span>{category?.userCount}</span>
                                        </div>
                                        <div className='mx-5 text-center'>
                                            <h5>Post Counts</h5>
                                            <span>{category?.postCount}</span>
                                        </div>
                                    </div>
                                    <div className="card-body mt-5">
                                        <ul className="nav nav-tabs nav-tabs-pix mt-1" role="tablist">
                                            <li className="nav-item" role="presentation"><a className="nav-link active" href="#userlist" data-bs-toggle="tab" aria-selected="true" role="tab">User List</a></li>
                                            <li className="nav-item" role="presentation"><a className="nav-link" href="#imagelist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Post List</a></li>
                                        </ul>
                                        <div className="tab-content tab-content-pix">
                                            <div className="tab-pane active show" id="userlist" role="tabpanel">
                                                <UserDetails category={category}/>
                                            </div>
                                            <div className="tab-pane" id="imagelist" role="tabpanel">
                                                <PostDetails  category={category} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CategoryView;