import { useEffect, useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { toast } from "react-toastify";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Pagination,
} from "@mui/material";
import { s3Url } from "../../service/FileUpload";
import { deleteSlider, getFilterSlider, saveSlider, updateSlider } from "../../service/slider";
import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";

function SliderList() {

	let initialStateInputs = {
		link: "",
		imageUrl: "",
		key:"",
	}

	let initialStateErrors = {
		imageUrl: { required: false },
	};
	const [errors, setErrors] = useState(initialStateErrors);
	const [slider, setSlider] = useState();
	const [open, setOpen] = useState(false);
	const [remove, setRemove] = useState(false);
	const [inputs, setInputs] = useState(initialStateInputs);
	const pageSize = 10;

	const [pagination, setPagination] = useState({
		count: 0,
		from: 0,
		to: pageSize,
	});

	const data = {
		limit: 10,
		page: pagination.from,
	};

	useEffect(() => {
		getFilterSliderList();
	}, [pagination.from, pagination.to]);

	const getFilterSliderList = () => {
		getFilterSlider(data)
			.then((res) => {
				setSlider(res?.data?.result?.sliderList);
                console.log('rrrrrr',res);
				setPagination({
					...pagination,
					count: res?.data?.result?.sliderCount,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handlePageChange = (event, page) => {
		const from = (page - 1) * pageSize;
		const to = (page - 1) * pageSize + pageSize;
		setPagination({ ...pagination, from: from, to: to });
	};

	const openPopup = () => {
		setInputs(initialStateInputs)
		setOpen(true);
	};
	const closePopup = () => {
		setOpen(false);
	};

	const openDeletePopup = (data) => {
		setRemove(true)
		setInputs({ ...inputs, _id: data })
	}

	const closeDeletePopup = () => {
		setRemove(false)
	}



	const resetForm = () => {
		setInputs(initialStateInputs)
	}

	const handleInputs = (event) => {
		setInputs({ ...inputs, [event.target.name]: event.target.value });
	};

	const handleFileInputs = (event) => {
		const file = event.target.files[0];
		const fileName = file?.name;
		if(file){
			Storage.put(file.name, file).then(res => {
				var url = `${s3Url}public/` + res.key;
				var key = 'public/' + res.key;
				const imageUrl = url
				setInputs({ ...inputs, imageUrl: imageUrl, fileName: fileName,key })
			}).catch(err => { console.log(err); })
		}
	
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		let error = initialStateErrors;
		var hashError = false;
		
		if (inputs.imageUrl === "") {
			error.imageUrl.required = true;
			hashError = true;
		}
		setErrors({ ...error });
		if (!hashError) {
			if (inputs._id) {
				updateSlider(inputs)
					.then((res) => {
						toast.success(res?.data?.message);
						closePopup();
						getFilterSliderList()
					})
					.catch((err) => {
						console.log(err);
						toast.error(err?.response?.data?.message);
					});
			}
			else {
				saveSlider(inputs)
					.then((res) => {
						toast.success(res?.data?.message);
						closePopup();
						getFilterSliderList()
					})
					.catch((err) => {
						toast.error(err?.response?.data?.message);
					});
			}
		}
	};

	const updateSliderDialog = (data) => {
		const { link, fileName, _id,imageUrl,key } = data
		openPopup()
		setInputs({ ...inputs, link, fileName, _id,imageUrl,key })
	}



	const deleteSliderData = () => {
		deleteSlider(inputs._id).then(res => {
			toast.success(res?.data?.message);
			closeDeletePopup();
			getFilterSliderList()
		}).catch((err) => {
			toast.error(err?.response?.data?.message);
		});
	}

	return (
		<>
			<div id="main-wrapper">
				<Header />
				<SideBar />

				<div className="content-body">
					<div className="content container-fluid">
						<div className="row">
							<div className="content-page-header mb-20">
								<h5>Slider Management</h5>
								<div className="list-btn">
									<ul className="filter-list">
										<li>
											<button
												className="btn btn-pix-primary "
												onClick={openPopup}>
												<i
													className="fa fa-plus-circle me-2"
													aria-hidden="true"
												></i>
												Add Slider{" "}
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="card-table table-responsive overflow-auto">
                      <table className="card-table dataTable">
                        <thead>
                          <tr>
                            <th>Slider No.</th>
                            <th>Slider Image</th>
                            <th>Slider Link</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slider?.map((data, index) => (
                            <tr key={index}>
                              <td>#{pagination.from + index + 1}</td>
                              <td>
                              <img
                                    className="avatar-sm-post"
                                    src={s3Url+data?.key}
                                    alt="User"
                                  /> 
                              </td>
                              <td>{data?.link}
                              </td>
                              <td>
                      <div className="dropdown dropdown-action">
                        <a
                          href="/#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-horizontal"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" onClick={()=>updateSliderDialog(data)}  ><i className="far fa-edit me-2"></i>Edit</Link>
                          <Link className="dropdown-item" onClick={() => { openDeletePopup(data?._id) }} >
                            <i className="far fa-trash-alt me-2"></i>
                            Delete
                          </Link>
                        </div>
                      </div>
                    </td>
                            </tr>
                          ))}
                          {slider?.length === 0 ?
                            <tr>
                              <td className="form-text text-danger">
                                No data </td>
                            </tr> : null}
                        </tbody>
                      </table>
                    </div>
                    <div className='float-end my-2'>
                      <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
			<Dialog open={open} fullWidth maxWidth="sm">
				<DialogTitle>
					{(!inputs?._id)? 'Add Slider':'Update Slider'}
					<IconButton className="float-end" onClick={closePopup} >
						<i className="fa fa-times fa-xs" aria-hidden="true"></i>
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<label htmlFor="file-input" className="form-label">
								Select Image <span className="text-danger">*</span>
							</label>
							<div className="input-group">
								<label className='form-control' style={{ color: (!inputs.fileName) ? "rgb(194 200 206)" : "black" }} htmlFor='file-input'>
									{inputs.fileName ? inputs?.fileName : 'Choose File'}
								</label>
								<input
									type="file" onChange={handleFileInputs}
									name="imageUrl"
									id="file-input" style={{ width: '0' }} accept="image/*" />
								<div className='input-group-pend'>
									<label className='input-group-text form-control' htmlFor='file-input'>Browse </label>
								</div>
							</div>
							{errors.imageUrl.required ? <span className="form-text text-danger">
								This field is required.
							</span> : null}
						</div>
                        <div className="from-group mb-3">
							<label htmlFor="Link" className="form-label">
								Link 
							</label>
							<input
								type="text"
								className="form-control"
								id="Link"
								name="link"
								value={inputs.link}
								onChange={handleInputs}
								placeholder="Enter Link"
							/>
						</div>
						{!inputs._id ?
							<div><button type="button" onClick={resetForm} className="btn btn-cancel float-end">Reset</button>
								<button type="submit" className="btn btn-save float-end mx-2">Save</button></div> :
							<div><button type="button" onClick={closePopup} className="btn btn-cancel float-end">Cancel</button>
								<button type="submit" className="btn btn-save float-end mx-2">Update</button></div>}
					</form>
				</DialogContent>
			</Dialog>

			<Dialog open={remove}>
				<DialogContent>
					<div className="text-center m-4">
						<h5 className="mb-4">Are you sure you want to Delete <br /> the selected Slider ?</h5>
						<button type="button" className="btn btn-save mx-3" onClick={deleteSliderData}>Yes</button>
						<button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
}
export default SliderList;
