
import { useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getSingleStory } from '../../service/story';
import { localDate } from '../../service/validation';
import { s3Url } from '../../service/FileUpload';

function StoriesView() {
  const [story, setStory] = useState()
  const location = useLocation();
  const storyId = new URLSearchParams(location.search).get('id');
  useEffect(() => {
    getStoryDetail()
  }, [])
  const getStoryDetail = () => {
    getSingleStory(storyId).then(res => {
      const story = res?.data?.result
      setStory({ ...story, story })
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header page-header mb-20">
                <div className="col">
                  <h5>Post Story View</h5>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">Upload Content</Link></li>
                    <li className="breadcrumb-item active">Post Story Details</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="student-profile-head">
                          <div className="profile-bg-img"></div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="profile-user-box">
                                <div className="profile-user-img profile-user-img-1">
                                  {story?.fileType === 1 ?
                                  <img src={s3Url+story?.key} className="avatar-sm-post" alt='post' />
                                  : <video src={s3Url+story?.key} type="video/mp4" width="300" height="200" controls>
                                  </video>}
                                </div>
                                <div className="names-profiles">
                                  <h5>Post User</h5>
                                  <h4>{story?.user?.userName}</h4>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="student-personals-grp">
                          <div className="card">
                            <div className="card-body">
                              <div className="heading-detail">
                                <h4>Post Details:</h4>
                              </div>
                              <div className="col-lg-12 col-md-12 d-flex align-items-center">
                                <div className="follow-group students-follows-1">
                                  <div className="students-follows">
                                    <h5>Post Date</h5>
                                    <h4>{localDate(story?.createdOn)}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Like</h5>
                                    <h4>{story?.likeCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Comment</h5>
                                    <h4>{story?.commentCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>View</h5>
                                    <h4>{story?.viewedCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Status</h5>
                                    <h4>{story?.isActive ? "Active" : "Inactive"}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default StoriesView;