
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import {  s3Url } from '../../service/FileUpload';
import { getSingleAdminProfile, updateProfile } from '../../service/settings';
import { toast } from 'react-toastify';
import { getUserId } from '../../service/Storage';
import { isValidPassword } from '../../service/validation';
import { Link } from 'react-router-dom';
import { Storage } from 'aws-amplify';



function Setting() {

  let initialStateInputs = {
		userName: "",
		imageUrl: "",
    key:"",
    password:""
	}

  let initialStateErrors = {
    userName: { required: false },
    password: { required: false, valid: false },
  };

  const [errors, setErrors] = useState(initialStateErrors);

  const [inputs, setInputs] = useState(initialStateInputs)

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  }

  useEffect(() => {
    getProfileDetails()
  }, [])

  const getProfileDetails = () => {
    const userId = getUserId()
    getSingleAdminProfile(userId).then(res => {
      const{userName,imageUrl,password,_id,key}  = res?.data?.result;
     setInputs({...inputs,userName,imageUrl,password,_id,key})
    }).catch(err => {
      console.log(err);
    })
  }

  const handleFileInputs = (event) => {
    const file = event.target.files[0];
    if (file) {
      Storage.put(file.name, file).then(res => {
        var url = `${s3Url}public/` + res.key;
        var key = 'public/' + res.key;
				const imageUrl = url
        setInputs({ ...inputs, imageUrl: imageUrl,key })
			}).catch(err => { console.log(err); })
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
    if (inputs.userName === "") {
      error.userName.required = true;
      hashError = true;
    }
    if (inputs.password === "") {
      error.password.required = true;
      hashError = true;
    }
    if (!isValidPassword(inputs.password)) {
      error.password.valid = true;
      hashError = true;
    } 
    setErrors({ ...error });
    if (!hashError) {
    updateProfile(inputs)
      .then((res) => {
        toast.success(res?.data?.message);
        getProfileDetails()
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
    }
  }



  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Settings</h5>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-4 mt-2">
                <div className="card">
                  <div className="card-body">
                    <div className="widget settings-menu mb-0">
                      <ul>
                        <li className="nav-item">
                          <Link to="/Settings" className="nav-link active">
                            <i className="far fa-user"></i>
                            <span>Profile Settings</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Terms" className="nav-link">
                            <i className="far fa-file"></i>
                            <span>Terms and Conditions</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Privacy" className="nav-link">
                            <i className="fas fa-file"></i>
                            <span>Privacy Policy</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Notifications" className="nav-link">
                            <i className="far fa-bell"></i>
                            <span>Notifications</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/Password" className="nav-link">
                            <i className="fas fa-unlock-alt"></i>
                            <span>Change Password</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="card mt-2">
                  <div className="card-header"><div className="card-body">
                    <h5 className="card-title">Profile Settings</h5>
                  </div>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row form-group">
                        <label htmlFor="name" className="col-sm-3 col-form-label input-label">Photo</label>
                        <div className="col-sm-9">
                          <div className="d-flex align-items-center">
                            <label className="avatar avatar-xxl profile-cover-avatar m-0" htmlFor="edit_img">
                              <img id="avatarImg" className="avatar-img" src={(inputs?.imageUrl)?s3Url+inputs?.key:`${s3Url}empty_profile.png`} alt="Profile" />
                              <input type="file" onChange={handleFileInputs} id="edit_img" />
                              <span className="avatar-edit">
                                <i className="fe fe-edit avatar-uploader-icon shadow-soft"></i>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <label htmlFor="name" className="col-sm-3 col-form-label input-label">User Name</label>
                        <div className="col-sm-9">
                          <input type="text" className="form-control" id="name" name='userName' placeholder="Your Name" onChange={handleInputs} value={inputs?.userName} />
                          {errors.userName.required ? (
                            <span className="text-danger form-text">
                             This field is required.
                            </span>):null}
                        </div>
                      </div>
                      <div className="row form-group">
                        <label htmlFor="location" className="col-sm-3 col-form-label input-label">Password</label>
                        <div className="col-sm-9">
                          <div className="mb-3">
                            <input type="password" name='password' placeholder='Password' className="form-control" id="location" onChange={handleInputs} value={inputs?.password} />
                            {errors.password.required ? (
                            <span className="text-danger form-text">
                             This field is required.
                            </span>
                          ) : errors.password.valid ? (
                            <span className="text-danger form-text">
                              A minimum 8 characters password contains a
                              combination of {''}
                              <strong>uppercase and lowercase letter {''}</strong>
                              and <strong>number</strong>.
                            </span>
                          ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="text-end">
                        <button type="submit" className="btn btn-save">Save Changes</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
}
export default Setting;