
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import CoinsManagement from './coinsManagement';
import PlayBidding from './playBidding';
import PostBidding from './postBidding';


function Bidding() {
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Bidding Management</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-pix" role="tablist">
                      <li className="nav-item" role="presentation"><a className="nav-link active" href="#userlist" data-bs-toggle="tab" aria-selected="true" role="tab">Coins Management</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#imagelist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Post Bidding</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#videolist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-2">Play Bidding</a></li>
                    </ul>
                    <div className="tab-content tab-content-pix">
                      <div className="tab-pane active show" id="userlist" role="tabpanel">
                        <CoinsManagement/>
                      </div>
                      <div className="tab-pane" id="imagelist" role="tabpanel">
                        <PostBidding />
                      </div>
                      <div className="tab-pane" id="videolist" role="tabpanel">
                        <PlayBidding />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Bidding;