
import { useState } from "react";
import { useEffect } from "react";
import { Dialog, DialogContent, Pagination } from "@mui/material";
import { deleteStory, getAllStory } from "../../service/story";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { localDate } from "../../service/validation";
import { s3Url } from "../../service/FileUpload";
function StoriesList() {
  const pageSize = 10
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  const [story, setStory] = useState();
  const [deleteId, setDeleteId] = useState()
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState()
  const data = {
    isAdmin: true
  }
  useEffect(() => {
    getAllStoryList();
  }, []);

  const getAllStoryList = () => {
    getAllStory(data)
      .then((res) => {
        setStory(res?.data?.result?.storyList);
        setSearch(res?.data?.result?.storyList);
        setPagination({ ...pagination, count: res?.data?.result?.storyCount })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const openPopup = (data) => {
    setOpen(true);
    setDeleteId(data)
  };

  const closePopup = () => {
    setOpen(false);
  };

  const deleteStoryData = () => {
    deleteStory(deleteId).then(res => {
      toast.success(res?.data?.message);
      closePopup();
      getAllStoryList()
    }).catch((err) => {
      console.log(err);
    });
  }

  const searchList = (event) => {
    var value = event.target.value.toLowerCase();
    if (value) {
        var searchData = search.filter((x) => {
            return x?.user?.userName.toLowerCase().indexOf(value) !== -1 || !value
        })
        setStory(searchData)
        setPagination({ ...pagination, count: searchData?.length })
    }
    else {
      setStory(search)
        setPagination({ ...pagination, count: search?.length })
    }
}


  return (
    <>
      <div className="row">
        <div className="content-page-header mb-20">
          <h6>Stories List</h6>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="form-group-tab">
                    <input type="email" className="form-control"  onKeyUp={searchList} placeholder="Search Post User" />
                  </div>
                </div>
              </li>
              <li>
                <Link className="btn btn-pix-primary " to="/AddStories">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Stories</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card-table table-responsive overflow-auto">
            <table id="pixalive-table" className="dataTable">
              <thead>
                <tr>
                  <th>Post No.</th>
                  <th>Poster</th>
                  <th>File Type</th>
                  <th>Post User</th>
                  <th>Post Date</th>
                  <th>Like</th>
                  <th>Comment</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {story?.slice(pagination.from,pagination.to)?.map((data, index) => (
                  <tr key={index}>
                    <td>#{pagination.from + index + 1}</td>
                    <td>
                      {data?.fileType === 1 ?
                        <img
                          className="avatar-sm-post"
                          src={s3Url+data?.key}
                          alt="User"
                        /> : <video className="avatar-sm-post">
                          <source src={s3Url+data?.key} type="video/mp4" />
                          <source height={20} src={s3Url+data?.key} type="video/ogg" />
                        </video>}
                    </td>
                    <td>
                      {data?.fileType === 1 ? "Image" : "Video"}
                    </td>
                    <td>
                      <Link className=" pointer" to={{ pathname: '/StoriesView', search: `?id=${data?._id}` }}>
                        <h6 className="tb-name">
                          {data?.user?.userName}
                        </h6>
                      </Link>
                    </td>
                    <td>
                      {localDate(data?.createdOn)}
                    </td>
                    <td>{data?.likeCount}</td>
                    <td>{data?.commentCount}</td>
                    <td>{data?.viewedCount}</td>
                    <td>
                      {data?.isActive ? (
                        <span className="badge-soft-success">
                          Active
                        </span>
                      ) : (
                        <span className="badge-soft-danger">
                          Inactive
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="dropdown dropdown-action">
                        <a
                          href="/#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-horizontal"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to={{ pathname: '/StoriesEdit', search: `?id=${data?._id}` }} ><i className="far fa-edit me-2"></i>Edit</Link>
                          <Link className="dropdown-item" to={{ pathname: '/StoriesView', search: `?id=${data?._id}` }} ><i className="far fa-eye me-2"></i>View</Link>
                          <Link className="dropdown-item" onClick={() => { openPopup(data?._id) }}>
                            <i className="far fa-trash-alt me-2"></i>
                            Delete
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                    {story?.length === 0 ?
                                    <tr>
                                       <td className="form-text text-danger">
                                       No data 
                                       </td>
                                    </tr> : null}
              </tbody>
            </table>
          </div>
          <div className='float-end my-2'>
            <Pagination count={Math.ceil(pagination.count / pageSize)}
              onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
          </div>
        </div>
      </div>
      <Dialog open={open}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Story ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={deleteStoryData}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closePopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>

    </>)
}
export default StoriesList;