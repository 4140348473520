import { useEffect } from 'react';
import { useState } from 'react';
import { Chip, Pagination } from '@mui/material';
import { localDate } from '../../service/validation';
import React from 'react';
import { s3Url } from '../../service/FileUpload';

function UserDetails({ category }) {
    const pageSize = 10;
    const [search, setSearch] = useState()
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    })
    const [users, setUsers] = useState()

    useEffect(() => {
        getAllUserList()
    }, [category])

    const getAllUserList = () => {
        setUsers(category?.userList)
        setSearch(category?.userList)
        setPagination({ ...pagination, count: category?.userCount })
    }

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize
        setPagination({ ...pagination, from: from, to: to, })
    }


    const searchList = (event) => {
        var value = event.target.value.toLowerCase();
        if (value) {
            var searchData = search.filter((x) => {
                return x?.phone.toLowerCase().indexOf(value) !== -1 || !value;
            })
            setUsers(searchData)
            setPagination({ ...pagination, count: searchData?.length })
        }
        else {
            setUsers(search)
            setPagination({ ...pagination, count: search?.length })
        }
    }





    return (
        <>
            <div className="row">
                <div className="content-page-header mb-20">
                    <h6>User List</h6>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <div className="form-group-tab">
                                        <input type="email" className="form-control" placeholder="Search Phone No" onKeyUp={searchList} />
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card-table table-responsive overflow-auto">
                        <table id="pixalive-table" className="dataTable">
                            <thead>
                                <tr>
                                    <th>User No.</th>
                                    <th>User Id</th>
                                    <th>Full Name</th>
                                    <th>User Name</th>
                                    <th>Phone No</th>
                                    <th>Coins</th>
                                    <th>Email Id</th>
                                    <th>Registration Date</th>
                                    <th>Gender</th>
                                    <th>Account Info</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.slice(pagination.from, pagination.to)?.map((data, index) => {
                                    return (<tr key={index}>
                                        <td>#{pagination.from + index + 1}</td>
                                        <td>{data?.userId}</td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <div className="avatar avatar-md me-2">
                                                    <img className="avatar-img rounded-circle" src={s3Url + data?.key} alt="User" /></div>
                                                <span>{data?.name}</span>
                                            </h2>
                                        </td>

                                        <td>
                                            {data?.userName}
                                        </td>
                                        <td>{data?.phone}</td>
                                        <td>{data?.coins}</td>
                                        <td>{data?.email}</td>
                                        <td>{localDate(data?.createdOn)}</td>
                                        <td>{data?.gender}</td>
                                        <td>{data?.isPrivate ? 'Private' : 'Public'}</td>
                                        <td> <Chip size='small' color={data?.isActive === true ? 'success' : 'error'} label={data?.isActive === true ? 'Active' : 'Inactive'}></Chip></td>
                                    </tr>)
                                })}
                                {users?.length === 0 ?
                                    <tr>
                                        <td className="form-text text-danger">
                                            No data
                                        </td>
                                    </tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <div className='float-end my-2'>
                        <Pagination count={Math.ceil(pagination.count / pageSize)}
                            onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserDetails