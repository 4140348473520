import { useEffect } from 'react';
import { useState } from 'react';
import { deleteUser, getFilterUsers, updateUserStatus } from '../../service/user';
import { Dialog, DialogContent, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { localDate } from '../../service/validation';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { s3Url } from '../../service/FileUpload';

function UserList() {
    const pageSize = 10;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState()
    const [status, setStatus] = useState(false)
    const [active, setActive] = useState('')
    const [activeId, setActiveId] = useState('')
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openStatus = Boolean(anchorEl);
    const handleClick = (event,id) => {
      setAnchorEl(event.currentTarget);
      setActiveId(id)
    };
    const handleClose = () => {
        setAnchorEl(null);
      };
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    })
    const [users, setUsers] = useState()
    const [deleteId, setDeleteId] = useState()
    const data = {
        isAdmin: true
    }

    useEffect(() => {
        getFilterUserList()
    }, [])

    const getFilterUserList = () => {
        getFilterUsers(data).then(res => {
            setUsers(res?.data?.result?.userList)
            setSearch(res?.data?.result?.userList)
            setPagination({ ...pagination, count: res?.data?.result?.userCount })
        })
            .catch(err => {
                console.log(err);
            })
    }

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize
        setPagination({ ...pagination, from: from, to: to, })
    }

    const openPopup = (data) => {
        setOpen(true);
        setDeleteId(data)
    };

    const closePopup = () => {
        setOpen(false);
    };

    const deleteUserData = () => {
        deleteUser(deleteId).then(res => {
            toast.success(res?.data?.message);
            closePopup();
            getFilterUserList()
        }).catch((err) => {
            console.log(err);
        });
    }

    const searchList = (event) => {
        var value = event.target.value.toLowerCase();
        if (value) {
            var searchData = search.filter((x) => {
                return x?.phone.toLowerCase().indexOf(value) !== -1 || !value;
            })
            setUsers(searchData)
            setPagination({ ...pagination, count: searchData?.length })
        }
        else {
            setUsers(search)
            setPagination({ ...pagination, count: search?.length })
        }
    }

    const openStatusPopup = (status) => {
        setStatus(true)
        setActive(status);
      }
      
      const closeStatusPopup = () => {
        setStatus(false)
      }
      
      const handleUserStatus = () => {
        const data = { _id: activeId, isActive: active }
        updateUserStatus(data).then(res => {
          toast.success(res?.data?.message);
          getFilterUserList()
          closeStatusPopup()
        }).catch(err => console.log(err))
      }
      

    return (
        <>
            <div className="row">
                <div className="content-page-header mb-20">
                    <h6>User List</h6>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <div className="form-group-tab">
                                        <input type="email" className="form-control" placeholder="Search Phone No" onKeyUp={searchList} />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Link className="btn btn-pix-primary " to="/AddUser">
                                    <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add User </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card-table table-responsive overflow-auto">
                        <table id="pixalive-table" className="dataTable">
                            <thead>
                                <tr>
                                    <th>User No.</th>
                                    <th>User Id</th>
                                    <th>Full Name</th>
                                    <th>User Name</th>
                                    <th>Phone No</th>
                                    <th>Coins</th>
                                    <th>Email Id</th>
                                    <th>Registration Date</th>
                                    <th>Gender</th>
                                    <th>Account Info</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.slice(pagination.from,pagination.to)?.map((data, index) => {
                                    return (<tr key={index}>
                                        <td>#{pagination.from + index + 1}</td>
                                        <td>{data?.userId}</td>
                                        <td>
                                            <h2 className="table-avatar">
                                                <div className="avatar avatar-md me-2">
                                                    <img className="avatar-img rounded-circle" src={s3Url+data?.key} alt="User" /></div>
                                                <span>{data?.name}</span>
                                            </h2>
                                        </td>

                                        <td>
                                            <Link to={{ pathname: '/UserView', search: `?id=${data?._id}` }} className="tb-name">{data?.userName}</Link>
                                        </td>
                                        <td>{data?.phone}</td>
                                        <td>{data?.coins}</td>
                                        <td>{data?.email}</td>
                                        <td>{localDate(data?.createdOn)}</td>
                                        <td>{data?.gender}</td>
                                        <td>{data?.isPrivate ? 'Private' : 'Public'}</td>
                                        <td>
                                <Button
                                  id="fade-button"
                                  aria-controls={openStatus ? 'fade-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openStatus ? 'true' : undefined}
                                  onClick={(event)=>handleClick(event,data?._id)} size='small'
                                  style={{ textTransform: 'none' }}
                                  variant="contained" color={data?.isActive === true ? "success" : 'error'}
                                >
                                  {data?.isActive === true ? 'Active' : 'Inactive'}
                                </Button>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={openStatus}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={handleClose}><Link onClick={() =>  openStatusPopup(true) } >Active</Link></MenuItem>
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(false) }>Inactive</Link></MenuItem>
                                </Menu>
                              </td>
                                        <td>
                                            <div className="dropdown dropdown-action">
                                                <a href="/#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown"><i className="fe fe-more-horizontal"></i></a>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <Link className="dropdown-item" to={{ pathname: '/EditUser', search: `?id=${data?._id}` }}><i className="far fa-edit me-2"></i>Edit</Link>
                                                    <Link className="dropdown-item" to={{ pathname: '/UserView', search: `?id=${data?._id}` }}><i className="far fa-eye me-2"></i>View</Link>
                                                    <Link className="dropdown-item" onClick={() => { openPopup(data?._id) }} ><i className="far fa-trash-alt me-2"></i>Delete</Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                                {users?.length === 0 ?
                                    <tr>
                                       <td className="form-text text-danger">
                                       No data 
                                       </td>
                                    </tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <div className='float-end my-2'>
                        <Pagination count={Math.ceil(pagination.count / pageSize)}
                            onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                </div>
            </div>
            <Dialog open={open}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to Delete <br /> the selected User ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={deleteUserData}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={status}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to {active === true ? 'active' : 'inactive'} <br /> the selected User ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={handleUserStatus}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closeStatusPopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>
        </>
    )
}

export default UserList