
import { useState } from "react";
import { useEffect } from "react";
import { Chip, Pagination } from "@mui/material";
import { localDate } from "../../service/validation";
import React from 'react';
import { s3Url } from "../../service/FileUpload";

function PostDetails({ category }) {
    const [search, setSearch] = useState()
    const pageSize = 10
    const [pagination, setPagination] = useState({
        count: 0,
        from: 0,
        to: pageSize
    })

    const [post, setPost] = useState();
    
    useEffect(() => {
        getAllPostList();
    }, [category]);

    const getAllPostList = () => {
        console.log('categorycategory',category);
        setPost(category?.postList);
        setSearch(category?.postList)
        setPagination({ ...pagination, count: category?.postCount })
    };

    const handlePageChange = (event, page) => {
        const from = (page - 1) * pageSize
        const to = (page - 1) * pageSize + pageSize
        setPagination({ ...pagination, from: from, to: to, })
    }


    const searchList = (event) => {
        var value = event.target.value.toLowerCase();
        if (value) {
            var searchData = search.filter((x) => {
                return x?.user?.userName.toLowerCase().indexOf(value) !== -1 || !value
            })
            setPost(searchData)
            setPagination({ ...pagination, count: searchData?.length })
        }
        else {
            setPost(search)
            setPagination({ ...pagination, count: search?.length })
        }
    }

    return (
        <>
            <div className="row">
                <div className="content-page-header mb-20">
                    <h6>Post List</h6>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <div className="form-group-tab">
                                        <input type="email" className="form-control" onKeyUp={searchList} placeholder="Search User Name" />
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

                <div className="col-xl-12">
                    <div className="card-table table-responsive overflow-auto">
                        <table id="pixalive-table" className="dataTable">
                            <thead>
                                <tr>
                                    <th>Post No.</th>
                                    <th>Poster</th>
                                    <th>File Type</th>
                                    <th>User Name</th>
                                    <th>Post Date</th>
                                    <th>Like</th>
                                    <th>Comment</th>
                                    <th>Share</th>
                                    <th>View</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {post?.slice(pagination.from, pagination.to)?.map((data, index) => (
                                    <tr key={index}>
                                        <td>#{pagination.from + index + 1}</td>
                                        <td>
                                            {data?.fileType === 1 ?
                                                <img
                                                    className="avatar-sm-post"
                                                    src={s3Url + data?.key}
                                                    alt="User"
                                                /> : <video src={s3Url + data?.key} type="video/mp4" className="avatar-sm-post">
                                                </video>}
                                        </td>
                                            <td>
                                                {data?.fileType === 1 ? "Image" : "Video"}
                                            </td>
                                            <td>
                                            <h6 className="tb-name">
                                                {data?.user?.userName}
                                            </h6>
                                            </td>
                                        <td>
                                            {localDate(data?.createdOn)}
                                        </td>
                                        <td>{data?.likeCount}</td>
                                        <td>{data?.commentCount}</td>
                                        <td>{data?.shareCount}</td>
                                        <td>{data?.viewCount}</td>
                                        <td> <Chip size="small" color={data?.isActive === true ? 'success' : 'error'} label={data?.isActive === true ? 'Active' : 'Inactive'}></Chip></td>
                                    </tr>
                                ))}
                                {post?.length === 0 ?
                                    <tr>
                                        <td className="form-text text-danger">
                                            No data
                                        </td>
                                    </tr> : null}
                            </tbody>
                        </table>
                    </div>
                    <div className='float-end my-2'>
                        <Pagination count={Math.ceil(pagination.count / pageSize)}
                            onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                </div>
            </div>
        </>)
}

export default PostDetails;