
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ResetPassword from "./pages/Login/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserList from "./pages/UserManagement/UserList";
import PostList from "./pages/PostManagement/PostList";
import CategoryList from "./pages/CategoryManagement/CategoryList";
import TrendingPeople from "./pages/Trending/TrendingPeople";
import UploadContent from "./pages/UploadContent/UploadContent";
import Reports from "./pages/Reports/Reports";
import PushNotification from "./pages/PushNotification/PushNotification";
import AddUser from "./pages/UploadContent/AddUser";
import { ToastContainer } from "react-toastify";
import Plays from "./pages/PostManagement/Plays";
import Stores from "./pages/PostManagement/Stores";
import PostListView from "./pages/PostManagement/PostListView";
import PostStoriesView from "./pages/PostManagement/PostStoriesView";
import PostPlayView from "./pages/PostManagement/PostPlayView";
import AddImage from "./pages/UploadContent/AddImage";
import ImageList from "./pages/UploadContent/ImageList";
import AddVideo from "./pages/UploadContent/AddVideo";
import AddPlays from "./pages/UploadContent/AddPlays";
import Password from "./pages/Settings/Password";
import Notifications from "./pages/Settings/Notifications";
import Privacy from "./pages/Settings/Privacy";
import Terms from "./pages/Settings/Terms";
import UserDetail from "./pages/Trending/UserDetail";
import StoriesEdit from "./pages/UploadContent/StoriesEdit";
import StoriesView from "./pages/UploadContent/StoriesView";
import PlayEdit from "./pages/UploadContent/PlayEdit";
import PlayView from "./pages/UploadContent/PlayView";
import VideoEdit from "./pages/UploadContent/VideoEdit";
import VideoView from "./pages/UploadContent/VideoView";
import ImageEdit from "./pages/UploadContent/ImageEdit";
import ImageView from "./pages/UploadContent/ImageView";
import UserView from "./pages/UploadContent/UserView";
import AddStories from "./pages/UploadContent/AddStories";
import EditUser from "./pages/UploadContent/EditUser";
import ViewUser from "./pages/UserManagement/ViewUser";
import Setting from "./pages/Settings/Settings";
import Slider from "./pages/Trending/Slider"
import Bidding from "./pages/BiddingManagement/bidding";
import { Amplify } from 'aws-amplify';
import CategoryView from "./pages/CategoryManagement/CategoryView";
Amplify.configure({
  Auth: {
    identityPoolId: 'ap-south-1:d4f2adeb-0668-4817-8218-f583501a44f4', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-south-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'ap-south-1_Ikp75X2Au', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '1ovr8aph6edahr0s5t56ljbo2l', //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'pixalive-textbucket163241-dev', //REQUIRED -  Amazon S3 bucket name
      region: 'ap-south-1', //OPTIONAL -  Amazon service region
      isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
    }
  }
});
const routes = (
  <Router>    
    <Routes>
    <Route path='/' element={<Login/>} />
    <Route path='/ForgotPassword' element={<ForgotPassword/>} />  
    <Route path='/ResetPassword' element={<ResetPassword/>} />  
    <Route path='/Dashboard' element={<Dashboard/>} />  
    <Route path='/AddUser' element={<AddUser/>} />  
    <Route path='/PostList' element={<PostList/>} />  
    <Route path='/CategoryList' element={<CategoryList/>} />  
    <Route path='/CategoryView' element={<CategoryView/>} />  
    <Route path='/TrendingPeople'element={<TrendingPeople/>} />  
    <Route path='/UploadContent'element={<UploadContent/>} />  
    <Route path='/Reports'element={<Reports/>} />  
    <Route path='/Settings'element={<Setting/>} />  
    <Route path='/PushNotification'element={<PushNotification/>} />  
    <Route path='/UserList' element={<UserList/>} />  
    <Route path='/Plays' element={<Plays/>} />  
      <Route path='/Stories' element={<Stores/>} />  
      <Route path='/AddImage' element={<AddImage/>} />  
      <Route path='/ImageList' element={<ImageList/>} />  
      <Route path='/AddVideo' element={<AddVideo/>} />  
      <Route path='/AddPlays' element={<AddPlays/>} />   
      <Route path='/ViewUser' element={<ViewUser/>} />  
      <Route path='/EditUser' element={<EditUser/>} />  
      <Route path='/AddStories' element={<AddStories/>} />  
      <Route path='/UserView' element={<UserView/>} />  
      <Route path='/ImageView' element={<ImageView/>} />  
      <Route path='/ImageEdit' element={<ImageEdit/>} />  
      <Route path='/VideoView' element={<VideoView/>} />  
      <Route path='/VideoEdit' element={<VideoEdit/>} />  
      <Route path='/PlayView' element={<PlayView/>} />  
      <Route path='/PlayEdit' element={<PlayEdit/>} /> 
      <Route path='/StoriesView' element={<StoriesView/>} />  
      <Route path='/StoriesEdit' element={<StoriesEdit/>} /> 
      <Route path='/UserDetail' element={<UserDetail/>} /> 
      <Route path='/Terms' element={<Terms/>} /> 
      <Route path='/Privacy' element={<Privacy/>} /> 
      <Route path='/Notifications' element={<Notifications/>} /> 
      <Route path='/Password' element={<Password/>} /> 
      <Route path='/PostPlayView' element={<PostPlayView/>} />  
      <Route path='/PostStoriesView' element={<PostStoriesView/>} />  
      <Route path='/PostListView' element={<PostListView/>} />
      <Route path='/Slider' element={<Slider/>} />
      <Route path='/Bidding' element={<Bidding/>} />

    </Routes>
 </Router> )

function App() {
  return (
    <div className="main-wrapper">
        {routes}  
        <ToastContainer/>
    </div>

  );
}

export default App;
