
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { useState } from 'react';
import {  s3Url } from '../../service/FileUpload';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { getFilterUsers } from '../../service/user';
import { getSingleStory, updateStory } from '../../service/story';
import { Storage } from 'aws-amplify';

function StoriesEdit () { 

  let initialStateErrors = {
    user: { required: false },
    url: { required: false },
  };
  const [errors, setErrors] = useState(initialStateErrors);
  const [inputs, setInputs] = useState();
  const [user, setUser] = useState()
  const location = useLocation();
  const navigate = useNavigate();
  const storyId = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    getAllUserList();
    getStoryDetails()
  }, [])

  const handleFileInputs = (event) => {
    const file = event?.target?.files[0];
    let fileType
    if (file?.type === 'video/mp4') {
      fileType = 2
    }
    else {
      fileType = 1
    }
    
    if (file) {
      Storage.put(file.name, file).then(res => {
				var url = `${s3Url}public/` + res.key;
				var key = 'public/' + res.key;
        setInputs({ ...inputs, url,key, fileType })
			}).catch(err => { console.log(err)})
    }
  };


  const getAllUserList = () => {
    const data = { isAdmin: true }
    getFilterUsers(data).then(res => {
      const userList = res?.data?.result?.userList;
      setUser(userList)
    }).catch(err => {
      console.log(err);
    })
  }

  const getStoryDetails = () => {
    getSingleStory(storyId).then(res => {
      const post = res?.data?.result;
      post.user = post?.user?._id
      setInputs(post)
    }).catch(err => {
      console.log(err);
    })
  }


  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target?.name]: event.target.value })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = initialStateErrors;
    var hashError = false;
   
    if (inputs.url === "") {
      error.url.required = true;
      hashError = true;
    }
    if (inputs.user === "") {
      error.user.required = true;
      hashError = true;
    }
    setErrors({ ...error });
    if (!hashError) {
      updateStory(inputs)
        .then((res) => {
          toast.success(res?.data?.message);
          navigate("/UploadContent")
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }

	return( 
	<>
    <div id="main-wrapper">
    <Header />
    <SideBar />
      <div className="content-body">
        <div className="content container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="row">
              <div className="content-page-header page-header mb-20">
                <div className="col">
                  <h5> Stories Edit</h5>
                  <ul className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/UploadContent">Upload Content</Link></li>
                      <li className="breadcrumb-item active"> Stories Edit</li>
                    </ul>
                </div>              
              </div>
            </div>
          
            <div className="row">
                <div className="col-xl-12">
                  <div className="card mt-2">
                    <div className="card-body">
                      <div className="row">
                      <div className="row col-lg-12 col-md-6 col-sm-12">
                          <div className=" col-lg-6 upload-img form-group">
                            <label>Upload</label>
                            <div className="image-upload image-upload-logo">
                              <input type="file" name='url' onChange={handleFileInputs} accept="image/*, video/*" />
                              <div className="image-uploads im-upload-logo">
                                {inputs?.fileType === 1 ?
                                  <img src={(inputs?.url) ? (s3Url+inputs?.key) : "./assets/images/upload.svg"} alt="img" />
                                  : <video src={s3Url+inputs?.key} poster={(!inputs?.url) ? './assets/images/upload.svg' : null} className="avatar-sm-post">
                                  </video>}
                                <h4>Upload</h4>
                              </div>
                            </div>
                            {errors.url.required ? <span className="form-text text-danger">
                              This field is required.
                            </span> : null}
                          </div>
                          <div  className= {inputs?.fileType===1?"mt-4 ms-5 col-lg-6":"mt-3 ms-5 col-lg-6"}>
                            {(inputs?.url) ?
                              (inputs?.fileType === 1 ?
                                <img src={s3Url+inputs?.key} width={150} alt='post' />
                                : <video src={s3Url+inputs?.key} type="video/mp4" width="300" height="200" controls>
                                </video>) : null}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Post User</label>
                            <select  className="form-select" value={inputs?.user??""} onChange={handleInputs} name='user'>
                              <option value={""} disabled hidden >Select Post User</option>
                              {user?.map((data, index) =>
                                <option key={index} value={data?._id}>{data?.userName}</option>)}
                            </select>
                            {errors.user.required ? <span className="form-text text-danger">
                              This field is required.
                            </span> : null}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Like</label>
                            <input type="text" value={inputs?.likeCount} id="mobile_code" className="form-control" onChange={handleInputs} placeholder="Enter Like" name="likeCount" />
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Comment</label>
                            <input type="text" value={inputs?.commentCount} className="form-control" name='commentCount' onChange={handleInputs} placeholder="Enter Comment" />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>View</label>
                            <input type="text" value={inputs?.viewedCount} className="form-control" placeholder="Enter View" name='viewedCount' onChange={handleInputs} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Post Type</label>
                            <select className="form-select" value={inputs?.storyType} onChange={handleInputs} name='storyType'>
                              <option value={1}>Public</option>
                              <option value={2}>Private</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label>Status</label>
                            <select className="form-select" value={inputs?.isActive} name='isActive' onChange={handleInputs} >
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="add-customer-btns text-end mb-40">
                        <Link to="/UploadContent" className="btn btn-cancel">Cancel</Link>
                        <button type='submit' className="btn btn-save">Update</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </form>
          </div>
        </div>
      </div>
	
  </> 
  ); 
  } 
  export default StoriesEdit;