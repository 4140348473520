import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { clearStorage, getUserId } from '../service/Storage';
import { toast } from 'react-toastify';
import { getSingleAdminUser } from '../service/settings';
import { getFilterPlayReport } from '../service/play';
import { getFilterPostReport } from '../service/post';
import { localDate } from '../service/validation';
import { s3Url } from '../service/FileUpload';

function Header() {

   const logout = () => {
      clearStorage();
      toast.success('You have logged out successfully.')
   }

   const [inputs, setInputs] = useState()
   const [playReport, setPlayReport] = useState([])
   const [postReport, setPostReport] = useState([])
   const [reports, setReports] = useState([])


   useEffect(() => {
      getProfileDetails()
      getAllPlayReportList()
      getAllPostReportList()
   }, [])

   useEffect(() => {
      if (postReport && playReport)
         playReport?.forEach(x => postReport?.push(x))
      setReports(postReport)
   }, [postReport && playReport])

   const getProfileDetails = () => {
      const userId = getUserId()
      getSingleAdminUser(userId).then(res => {
         const { userName, imageUrl,key } = res?.data?.result;
         setInputs({ ...inputs, userName, imageUrl,key })
      }).catch(err => {
         console.log(err);
      })
   }

   const getAllPlayReportList = () => {
      const data = {
         limit: 5,
      }
      getFilterPlayReport(data)
         .then((res) => {
            setPlayReport(res?.data?.result?.playReportList);
         })
         .catch((err) => {
            console.log(err);
         });
   };

   const getAllPostReportList = () => {
      const data = {
         limit: 5,
      }
      getFilterPostReport(data)
         .then((res) => {
            setPostReport(res?.data?.result?.postReportList);
         })
         .catch((err) => {
            console.log(err);
         });
   };


   return (
      <>

         <div className="header">
            <div className="header-content">
               <nav className="navbar navbar-expand">
                  <div className="collapse navbar-collapse justify-content-between">
                     <div className="header-left">
                        {/* <div className="top-nav-search">
                        <form>
                           <input type="text" className="form-control" placeholder="Search here"/>
                           <button className="btn" type="submit"><img src="../assets/images/search.svg" alt="img"/></button>
                        </form>
                     </div> */}
                     </div>
                     <div className="header-right d-flex align-items-center">
                        <ul className="nav nav-tabs user-menu">
                           <li className="nav-item dropdown  flag-nav dropdown-heads">
                              <a className="nav-link" data-bs-toggle="dropdown" href="/#" role="button">
                                 <i className="fe fe-bell"></i> <span className="badge rounded-pill"></span>
                              </a>
                              <div className="dropdown-menu notifications">
                                 <div className="topnav-dropdown-header">
                                    <span className="notification-title">Notifications</span>
                                    {/* <a href="/#" className="clear-noti"> Clear All</a> */}
                                 </div>
                                 <div className="noti-content">
                                    <ul className="notification-list">
                                       {reports?.map((data, index) =>
                                          data?.report?.map((x, i) =>
                                             <li key={i} className="notification-message">
                                                <Link to="/Reports">
                                                   <div className="media d-flex">
                                                      <span className="avatar avatar-sm-1">
                                                         <img className="avatar-img rounded-circle" alt="" src={s3Url+x?.user?.key} />
                                                      </span>
                                                      <div className="media-body">
                                                         <p className="noti-details"><span className="noti-title">{x?.type}</span></p>
                                                         <p className="noti-time"><span className="notification-time">{x?.createdOn ? localDate(x?.createdOn) : null}</span></p>
                                                      </div>
                                                   </div>
                                                </Link>
                                             </li>))}
                                    </ul>
                                 </div>
                                 <div className="topnav-dropdown-footer">
                                    <Link to="/Reports">View all Notifications</Link>
                                 </div>
                              </div>
                           </li>
                           <li className="nav-item dropdown">
                              <a href="/#" className="user-link  nav-link" data-bs-toggle="dropdown">
                                 <span className="user-img">
                                    <img src={(inputs?.imageUrl) ? s3Url+inputs?.key : `${s3Url}empty_profile.png`} alt="img" className="profilesidebar" />
                                    <span className="animate-circle"></span>
                                 </span>
                                 <span className="user-content">
                                    <span className="user-details">Admin</span>
                                    <span className="user-name">{inputs?.userName}</span>
                                 </span>
                              </a>
                              <div className="dropdown-menu menu-drop-user">
                                 <div className="profilemenu">
                                    <div className="subscription-menu">
                                       <ul>
                                          <li>
                                             <Link className="dropdown-item" to="/Settings">Profile</Link>
                                          </li>
                                          <li>
                                             <Link className="dropdown-item" to="/Settings">Settings</Link>
                                          </li>
                                       </ul>
                                    </div>
                                    <div className="subscription-logout">
                                       <ul>
                                          <li className="pb-0">
                                             <Link className="dropdown-item" onClick={logout} to='/' >Log Out</Link>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </li>
                        </ul>

                     </div>
                  </div>
               </nav>
            </div>
         </div>

      </>
   );
}

export default Header;