import { Link, Navigate } from "react-router-dom";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import { isAuthenticated } from "../../service/Auth";
import { useEffect, useState } from "react";
import {
  getChartDetails,
  getDashboardDetails,
  getRecentUserAndPost,
} from "../../service/dashboard";
import ReactApexChart from "react-apexcharts";
import { localDate } from "../../service/validation";
import { s3Url } from "../../service/FileUpload";

function Dashboard() {
  const month = [{ id: 1, label: 'Jan' }, { id: 2, label: 'Feb' }, { id: 3, label: 'Mar' }, { id: 4, label: 'Apr' },
  { id: 5, label: 'May' }, { id: 6, label: 'Jun' }, { id: 7, label: 'Jul' }, { id: 8, label: 'Aug' }, { id: 9, label: 'Sep' }, { id: 10, label: 'Oct' }, { id: 11, label: 'Nov' }, { id: 12, label: 'Dec' }];
  const userChartList = [];
  const userChartLabel = [];
  const postChartLabel = [];
  const postChartList = [];
  const playChartLabel = [];
  const playChartList = [];
  const [totalCounts, setTotalCounts] = useState();
  const [list, setList] = useState();
  useEffect(() => {
    if (isAuthenticated()) {
      DashboardDetails();
      recentUserAndPostList();
      userChartDetails()
    }
  }, []);
  const [userState, setUserState] = useState({
    chartOptions: {},
    series: []
  })
  const [postState, setPostState] = useState({
    chartOptions: {},
    series: [],
  })

  const [playState, setPlayState] = useState({
    chartOptions: {},
    series: [],
  })

  const DashboardDetails = () => {
    getDashboardDetails()
      .then((res) => {
        setTotalCounts(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userChartDetails = () => {
    getChartDetails().then(res => {
      let userList = res?.data?.result?.users
      let postList = res?.data?.result?.posts
      let playList = res?.data?.result?.plays
      let currentMonth = new Date().getMonth()+2
      let mon = currentMonth + 12
      for (let index = currentMonth; index < mon; index++) {
        let monthId
        if (index > 12) {
          monthId = index - 12
        }
        else {
          monthId = index
        }
        let months = month.find(x => x.id === monthId)
        const data = userList.find(x => x.month === months.id)
        if (data) {
          userChartList.push(data.total)
        }
        else {
          userChartList.push(0)
        }        
        userChartLabel.push(`${months.label} ${data?.year || '....'}`)
        const postData = postList.find(x => x.month === months.id)
        postChartLabel.push(`${months.label} ${postData?.year || data?.year || '....'}`)
        if (postData) {
          postChartList.push(postData.total)
        }
        else {
          postChartList.push(0)
        }

        const playData = playList.find(x => x.month === months.id)
        playChartLabel.push(`${months.label} ${playData?.year|| '....'}`)
        if (playData) {
          playChartList.push(playData.total)
        }
        else {
          playChartList.push(0)
        }
      }

      const chartOptions = {
        chart: {
          height: 300,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        grid: {
          row: {
            colors: ['transparent'],
            opacity: 0.2
          },
        },
        xaxis: {
          type: 'day',
          categories: userChartLabel
        },
        colors: ['#5b298c', '#fff'],
        markers: {
          size: 5,
          colors: ['#5b298c', '#5b298c'],
          strokeColor: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        }
      }

      let series = [{
        name: 'User',
        data: userChartList
      }]
      const postChartOptions = {
        chart: {
          height: 305,
          type: 'line',
          toolbar: {
            show: false,
          }
        },
        colors: ['#5b298c', '#888ea8'],

        stroke: {
          width: [0, 4]
        },

        xaxis: {
          categories: postChartLabel
        },
      }
      let postSeries = [{
        name: 'User',
        type: 'column',
        data: userChartList
      },
      {
        name: 'Post',
        type: 'line',
        data: postChartList
      }]

      const playChartOptions = {
        chart: {
          height: 305,
          type: 'line',
          toolbar: {
            show: false,
          }
        },
        colors: ['#5b298c', '#888ea8'],
        stroke: {
          width: [0, 4]
        },
        xaxis: {
          categories: playChartLabel
      },
        // chart: {
        //   type: 'donut',
        // },
        // labels:playChartLabel ,
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       width: 200
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }]
      }
      let playSeries = [{
        name: 'Play',
        type: 'column',
        data: playChartList
      }]
      
      setUserState({ ...userState, series: series, chartOptions: chartOptions })
      setPostState({ ...postState, series: postSeries, chartOptions: postChartOptions })
      setPlayState({ ...playState, series: playSeries, chartOptions: playChartOptions })

    })
      .catch(err => {
        console.log(err);
      })
  }




  const recentUserAndPostList = () => {
    getRecentUserAndPost()
      .then((res) => {
        setList(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon dash-bg">
                        <i className="fe fe-user"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Total Users</div>
                        <div className="dash-counts">
                          <p>{totalCounts?.users}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon dash-bg">
                        <i className="fe fe-sidebar"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Total Posts</div>
                        <div className="dash-counts">
                          <p>{totalCounts?.posts}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon dash-bg">
                        <i className="fe fe-play-circle"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Total Plays</div>
                        <div className="dash-counts">
                          <p>{totalCounts?.plays}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon dash-bg">
                        <i className="fe fe-tv"></i>
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">Total Stories</div>
                        <div className="dash-counts">
                          <p>{totalCounts?.stories}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-body">
                      <h5 className="card-title">Users Analytics</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <ReactApexChart options={userState.chartOptions} series={userState.series} type="area" height={300} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-body">
                      <h5 className="card-title">Post Analytics</h5>
                    </div>
                  </div>
                  <div className="card-body">
                    <ReactApexChart options={postState.chartOptions} series={postState.series} type="area" height={300} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-body">
                      <h5 className="card-title">Plays Analytics</h5>
                    </div>
                  </div>
                  <div className="card-body">
                  <ReactApexChart options={playState.chartOptions} series={playState.series} type="area" height={300} />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="card">
                  <div className="card-header">
                    <div className="card-body">
                      <div className="row align-center">
                        <div className="col">
                          <h5 className="card-title">Recent User</h5>
                        </div>
                        <div className="col-auto">
                          <Link to="/UserList" className="btn-pix-view btn">
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive overflow-auto">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>User Name</th>
                          <th>User Id</th>
                          <th>Register Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.usersList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={s3Url+data?.key}
                                  className="avatar-sm-2 rounded-circle "
                                  alt="..."
                                />
                                <span className="avatar-sm-online"></span>
                              </td>
                              <td>
                                <h6 className="tb-name">{data?.userName}</h6>
                              </td>
                              <td>
                                <h6 className="tb-user-id-list">
                                  {data?.userId}
                                </h6>
                              </td>
                              <td>{localDate(data?.createdOn)}</td>
                              <td>
                                {data?.isActive ? (
                                  <span className="badge-soft-success">
                                    Active
                                  </span>
                                ) :
                                  (<span className="badge-soft-danger">
                                    Inactive
                                  </span>)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-body">
                      <div className="row align-center">
                        <div className="col">
                          <h5 className="card-title">Recent Post</h5>
                        </div>
                        <div className="col-auto">
                          <Link to="/PostList" className="btn-pix-view btn">
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-responsive overflow-auto">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>Post No.</th>
                          <th>User Id</th>
                          <th>Post Type</th>
                          <th>Post Date</th>
                          <th>Like</th>
                          <th>Comment</th>
                          <th>Share</th>
                          <th>View</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.postsList?.map((data, index) => {
                          return (<tr key={index}>
                            <td>#{index + 1}</td>
                            <td>
                              <h6 className="tb-name">{data?.user?.userId}</h6>
                            </td>
                            <td>{data?.fileType === 1 ? "Image" : "Video"}</td>
                            <td>{localDate(data?.createdOn)} </td>
                            <td>{data?.likeCount}</td>
                            <td>{data?.commentCount}</td>
                            <td>{data?.shareCount}</td>
                            <td>{data?.viewCount}</td>
                              <td>
                                {data?.isActive ? (
                                  <span className="badge-soft-success">
                                    Active
                                  </span>
                                ) :
                                  (<span className="badge-soft-danger">
                                    Inactive
                                  </span>)}
                              </td>
                          </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
