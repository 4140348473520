
import { useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getSinglePost } from '../../service/post';
import { localDate } from '../../service/validation';
import { s3Url } from '../../service/FileUpload';

function VideoView () { 
  const [post, setPost] = useState()
  const location = useLocation();
  const postId = new URLSearchParams(location.search).get('id');
  useEffect(() => {
    getPostDetail()
  }, [])
  const getPostDetail = () => {
    getSinglePost(postId).then(res => {
      const post = res?.data?.result
      setPost({ ...post, post })
    }).catch(err => {
      console.log(err);
    })
  }

	return( 
	<>
    <div id="main-wrapper">
    <Header />
    <SideBar />
      <div className="content-body">
        <div className="content container-fluid">
          <div className="row">
            <div className="content-page-header page-header mb-20">
                <div className="col">
                  <h5>Post Video View</h5>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/UploadContent">Upload Content</Link></li>
                    <li className="breadcrumb-item active">Post Video Details</li>
                  </ul>
                </div>              
              </div>
          </div>

          <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="student-profile-head">
                          <div className="profile-bg-img"></div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="profile-user-box">
                                <div className="profile-user-img profile-user-img-1">
                                <video src={s3Url+post?.key} width="300" height="200"  controls>
                            </video>
                                </div>
                                <div className="names-profiles">
                                  <h5>Post User</h5>
                                  <h4>{post?.user?.userName}</h4>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="student-personals-grp">
                          <div className="card">
                            <div className="card-body">
                              <div className="heading-detail">
                                <h4>Post Details:</h4>
                              </div>
                              <div className="col-lg-12 col-md-12 d-flex align-items-center">
                                <div className="follow-group students-follows-1">
                                  <div className="students-follows">
                                    <h5>Post Date</h5>
                                    <h4>{localDate(post?.createdOn)}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Like</h5>
                                    <h4>{post?.likeCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Comment</h5>
                                    <h4>{post?.commentCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Share</h5>
                                    <h4>{post?.shareCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>View</h5>
                                    <h4>{post?.viewCount}</h4>
                                  </div>
                                  <div className="students-follows">
                                    <h5>Status</h5>
                                    <h4>{post?.isActive ? "Active" : "Inactive"}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>        
          </div>
        </div>
    </div>
	
  </> 
  ); 
  } 
  export default VideoView;