
import { useState } from "react";
import { deletePost, getAllPosts, updatePostStatus } from "../../service/post";
import { useEffect } from "react";
import { Dialog, DialogContent, Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { localDate } from "../../service/validation";
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { s3Url } from "../../service/FileUpload";

function VideoList() {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const [search, setSearch] = useState()
  const pageSize = 10
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  const [post, setPost] = useState();
  const [status, setStatus] = useState(false)
  const [active, setActive] = useState('')
  const [activeId, setActiveId] = useState('')

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openStatus = Boolean(anchorEl);
  const handleClick = (event,id) => {
    setAnchorEl(event.currentTarget);
    setActiveId(id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const data = {
    isAdmin: true,
    fileType: 2
  }
  useEffect(() => {
    getAllPostList();
  }, []);

  const getAllPostList = () => {
    getAllPosts(data)
      .then((res) => {
        setPost(res?.data?.result?.postList);
        setSearch(res?.data?.result?.postList);
        setPagination({ ...pagination, count: res?.data?.result?.postCount })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const openPopup = (data) => {
    setOpen(true);
    setDeleteId(data)
  };

  const closePopup = () => {
    setOpen(false);
  };

  const deletePostData = () => {
    deletePost(deleteId).then(res => {
      toast.success(res?.data?.message);
      closePopup();
      getAllPostList()
    }).catch((err) => {
      console.log(err);
    });
  }

  const searchList = (event) => {
    var value = event.target.value.toLowerCase();
    if (value) {
      var searchData = search.filter((x) => {
        return x?.user?.userName.toLowerCase().indexOf(value) !== -1 || !value
      })
      setPost(searchData)
      setPagination({ ...pagination, count: searchData?.length })
    }
    else {
      setPost(search)
      setPagination({ ...pagination, count: search?.length })
    }
  }

  const openStatusPopup = (status) => {
    setStatus(true)
    setActive(status);
  }

  const closeStatusPopup = () => {
    setStatus(false)
  }

  const handlePostStatus = () => {
    const data = { _id: activeId, isActive: active }
    updatePostStatus(data).then(res => {
      toast.success(res?.data?.message);
      getAllPostList()
      closeStatusPopup()
    }).catch(err => console.log(err))
  }


  return (
    <>
      <div className="row">
        <div className="content-page-header mb-20">
          <h6>Video List</h6>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <div className="col-lg-12 col-md-6 col-sm-12">
                  <div className="form-group-tab">
                    <input type="email" className="form-control" onKeyUp={searchList} placeholder="Search User Name" />
                  </div>
                </div>
              </li>
              <li>
                <Link className="btn btn-pix-primary " to="/AddVideo">
                  <i className="fa fa-plus-circle me-2" aria-hidden="true"></i>Add Video</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card-table table-responsive overflow-auto">
            <table id="pixalive-table" className="dataTable">
              <thead>
                <tr>
                  <th>Post No.</th>
                  <th>Poster</th>
                  <th>User Name</th>
                  <th>Post Date</th>
                  <th>Like</th>
                  <th>Comment</th>
                  <th>Share</th>
                  <th>View</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {post?.slice(pagination.from,pagination.to)?.map((data, index) => (
                  <tr key={index}>
                    <td>#{pagination.from + index + 1}</td>
                    <td>
                      <video src={s3Url+data?.key} type="video/mp4" className="avatar-sm-post">
                      </video>
                    </td>
                    <td>
                      <Link className=" pointer" to={{ pathname: '/VideoView', search: `?id=${data?._id}` }}>
                        <h6 className="tb-name">
                          {data?.user?.userName}
                        </h6>
                      </Link>
                    </td>
                    <td>
                      {localDate(data?.createdOn)}
                    </td>
                    <td>{data?.likeCount}</td>
                    <td>{data?.commentCount}</td>
                    <td>{data?.shareCount}</td>
                    <td>{data?.viewCount}</td>
                    <td>
                                <Button
                                  id="fade-button"
                                  aria-controls={openStatus ? 'fade-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openStatus ? 'true' : undefined}
                                  onClick={(event)=>handleClick(event,data?._id)} size='small'
                                  style={{ textTransform: 'none' }}
                                  variant="contained" color={data?.isActive === true ? "success" : 'error'}
                                >
                                  {data?.isActive === true ? 'Active' : 'Inactive'}
                                </Button>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={openStatus}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={handleClose}><Link onClick={() =>  openStatusPopup(true) } >Active</Link></MenuItem>
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(false) }>Inactive</Link></MenuItem>
                                </Menu>
                              </td>
                    <td>
                      <div className="dropdown dropdown-action">
                        <a
                          href="/#"
                          className="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fe fe-more-horizontal"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to={{ pathname: '/VideoEdit', search: `?id=${data?._id}` }} ><i className="far fa-edit me-2"></i>Edit</Link>
                          <Link className="dropdown-item" to={{ pathname: '/VideoView', search: `?id=${data?._id}` }}><i className="far fa-eye me-2"></i>View</Link>
                          <Link className="dropdown-item" onClick={() => { openPopup(data?._id) }} >
                            <i className="far fa-trash-alt me-2"></i>
                            Delete
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {post?.length === 0 ?
                  <tr>
                    <td className="form-text text-danger">
                       No data 
                    </td>
                  </tr> : null}
              </tbody>
            </table>
          </div>
          <div className='float-end my-2'>
            <Pagination count={Math.ceil(pagination.count / pageSize)}
              onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
          </div>
        </div>
      </div>
      <Dialog open={open}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Post ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={deletePostData}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closePopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={status}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to {active === true ? 'active' : 'inactive'} <br /> the selected Post ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={handlePostStatus}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closeStatusPopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>
    </>)
}
export default VideoList;