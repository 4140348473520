
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import VideoList from './VideoList';
import UserList from './userList';
import ImageList from './ImageList'
import PlayList from './PlayList';
import StoriesList from './storiesList';

function UploadContent() {
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />
        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Upload Content</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-pix" role="tablist">
                      <li className="nav-item" role="presentation"><a className="nav-link active" href="#userlist" data-bs-toggle="tab" aria-selected="true" role="tab">User List</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#imagelist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-1">Image List</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#videolist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-2">Video List</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#playslist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-3">Plays List</a></li>
                      <li className="nav-item" role="presentation"><a className="nav-link" href="#storieslist" data-bs-toggle="tab" aria-selected="false" role="tab" tabIndex="-4">Stories List</a></li>
                    </ul>
                    <div className="tab-content tab-content-pix">
                      <div className="tab-pane active show" id="userlist" role="tabpanel">
                        <UserList />
                      </div>
                      <div className="tab-pane" id="imagelist" role="tabpanel">
                        <ImageList />
                      </div>
                      <div className="tab-pane" id="videolist" role="tabpanel">
                        <VideoList />
                      </div>
                      <div className="tab-pane" id="playslist" role="tabpanel">
                        <PlayList />
                      </div>
                      <div className="tab-pane" id="storieslist" role="tabpanel">
                        <StoriesList />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UploadContent;