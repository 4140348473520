import axios from './interceptor';


export const getFilterCoins = (data) => {
    return axios.put("/coins/getFilterAdminCoins",data);
  };

  export const updateCoins = (data) => {
    return axios.put("/coins",data);
  };


 