import axios from './interceptor'

export const updateProfile=(data)=>{
    return axios.put("/adminLogin/updateAdminProfile",data);
}

export const getSingleAdminUser=(data)=>{
    return axios.get("/adminLogin/getSingleAdminUser",{params:{_id:data}});
}
export const getSingleAdminProfile=(data)=>{
    return axios.get("/adminLogin/getSingleAdminProfile",{params:{_id:data}});
}

export const updateSettings =(data)=>{
    return axios.put("/settings",data)
}

export const getSettings =(data)=>{
    return axios.get("/settings",{params:{title:data}})
}

export const updatePassword =(data)=>{
    return axios.put("/adminLogin/updatePassword",data)
}