
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { getAllPlays, updatePlayStatus } from '../../service/play';
import { Dialog, DialogContent, DialogTitle, IconButton, Pagination } from '@mui/material';
import { templatePdf } from '../../service/pdfmake';
import { ExportCsvService } from '../../service/excel';
import { localDate } from '../../service/validation';
import { getFilterUsers } from '../../service/user';
import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from "react-toastify";
import { s3Url } from '../../service/FileUpload';



function Plays() {
  const initialStateInputs = {
    user: '',
    postDate: ''
  }
  const pageSize = 10
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize
  })

  const [play, setPlay] = useState();
  const [inputs, setInputs] = useState(initialStateInputs)
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState()
  const [status, setStatus] = useState(false)
  const [active, setActive] = useState('')
  const [activeId, setActiveId] = useState('')
  const [search, setSearch] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openStatus = Boolean(anchorEl);

  const handleClick = (event, id) => {
    setActiveId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = {
    isAdmin: false
  }
  useEffect(() => {
    getAllPlayList();
  }, []);

  const getAllPlayList = () => {
    getAllPlays(data)
      .then((res) => {
        setPlay(res?.data?.result?.playList);
        setSearch(res?.data?.result?.playList);
        setPagination({ ...pagination, count: res?.data?.result?.playCount })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (event, page) => {
    const from = (page - 1) * pageSize
    const to = (page - 1) * pageSize + pageSize
    setPagination({ ...pagination, from: from, to: to, })
  }

  const openFilterPopup = () => {
    setOpen(true)
    getAllUserList()
  }

  const closeFilterPopup = () => {
    setOpen(false)
  }

  const filterPostList = (event) => {
    event.preventDefault()
    const data = {
      user: inputs?.user,
      postDate: inputs?.postDate,
      isAdmin: false
    }
    getAllPlays(data)
      .then((res) => {
        setPlay(res?.data?.result?.playList);
        setSearch(res?.data?.result?.playList);
        setPagination({
          ...pagination,
          count: res?.data?.result?.playCount,
        });
        closeFilterPopup()
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const resetFilter = () => {
    setInputs(initialStateInputs)
    getAllPlayList()
  }

  const getAllUserList = () => {
    const data = { isAdmin: false }
    getFilterUsers(data).then(res => {
      const userList = res?.data?.result?.userList;
      setUser(userList)
    }).catch(err => {
      console.log(err);
    })
  }

  const handleInputs = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };


  const pdfDownload = () => {
    var data = {
      isAdmin: false
    }
    getAllPlays(data).then(res => {
      var result = res?.data?.result?.playList
      var tablebody = [];
      tablebody.push([
        {
          text: 'S.NO', fontSize: 11, alignment: 'center', margin: [5, 5], bold: true
        },
        {
          text: 'User Name', fontSize: 11, alignment: 'center', margin: [40, 5], bold: true
        },
        {
          text: 'Post Date', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Like', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Comment', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Share', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'View', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        },
        {
          text: 'Status', fontSize: 11, alignment: 'center', margin: [20, 5], bold: true
        }
      ])
      result.forEach((element, index) => {
        tablebody.push([
          {
            text: index + 1, fontSize: 10, alignment: 'left', margin: [5, 3], border: [true, false, true, true]
          },
          {
            text: element?.user?.userName.toUpperCase(), fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: localDate(element?.createdOn) ?? '-', fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.likeCount ?? 0, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.commentCount ?? 0, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.shareCount ?? 0, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: element?.viewedCount ?? 0, fontSize: 10, alignment: 'left', margin: [5, 3]
          },
          {
            text: (element?.isActive) ? 'Active' : 'Inactive', fontSize: 10, alignment: 'left', margin: [5, 3]
          }
        ])
      });
      templatePdf("Play List", tablebody, 'landscape')
    }).catch(err => { console.log(err); })
  }

  const exportCsv = () => {
    var data = {
      isAdmin: false
    }
    getAllPlays(data).then(res => {
      var result = res?.data?.result?.playList
      let list = [];
      result?.forEach(res => {
        list.push({
          userName: res?.user?.userName?.toUpperCase(), registrationDate: localDate(res?.createdOn) ?? '-',
          likeCount: res?.likeCount ?? 0, commentCount: res?.commentCount ?? 0, shareCount: res?.shareCount ?? 0, viewCount: res?.viewedCount ?? 0, isActive: (res?.isActive) ? 'Active' : 'Inactive'
        })
      })
      let header1 = ['userName', 'registrationDate', 'likeCount', 'commentCount', 'shareCount', 'viewCount', 'isActive'];
      let header2 = ['User Name', 'Registration Date', 'Like', 'Comment', 'Share', 'View', 'Status'];
      ExportCsvService.downloadCsv(list, 'playlist', 'Play List', header1, header2);
    }).catch(err => { console.log(err); })
  }

  const openStatusPopup = (status) => {
    setStatus(true)
    setActive(status);
  }

  const closeStatusPopup = () => {
    setStatus(false)
  }

  const handlePlayStatus = () => {
    const data = { _id: activeId, isActive: active }
    updatePlayStatus(data).then(res => {
      toast.success(res?.data?.message);
      getAllPlayList()
      closeStatusPopup()
    }).catch(err => console.log(err))
  }

  const searchList = (event) => {
    var value = event.target.value.toLowerCase();
    if (value) {
      var searchData = search.filter((x) => {
        return x?.user?.userName.toLowerCase().indexOf(value) !== -1 || !value;
      })
      setPlay(searchData)
      setPagination({ ...pagination, count: searchData?.length })
    }
    else {
      setPlay(search)
      setPagination({ ...pagination, count: search?.length })
    }
  }

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <SideBar />

        <div className="content-body">
          <div className="content container-fluid">
            <div className="row">
              <div className="content-page-header">
                <h5>Plays Management</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <div className="col-lg-12 col-md-6 col-sm-12">
                        <div className="form-group-tab">
                          <input type="email" className="form-control" placeholder="Search User Name" onKeyUp={searchList} />
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={pdfDownload} >
                        <span>
                          <i className="fa fa-file-pdf" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={exportCsv} >
                        <span>
                          <i className="fa fa-file-excel" aria-hidden="true"></i>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-filters" onClick={openFilterPopup}>
                        <span>
                          <i className="fe fe-filter"></i>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card mt-2">
                  <div className="card-body">
                    <div className="card-table table-responsive overflow-auto">
                      <table className="card-table dataTable">
                        <thead>
                          <tr>
                            <th>Post No.</th>
                            <th>Poster</th>
                            <th>User Name</th>
                            <th>Post Date</th>
                            <th>Like</th>
                            <th>Comment</th>
                            <th>Share</th>
                            <th>View</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {play?.slice(pagination.from,pagination.to)?.map((data, index) => (
                            <tr key={index}>
                              <td>#{pagination.from + index + 1}</td>
                              <td>
                                <video src={s3Url+data?.key} type="video/mp4" className="avatar-sm-post">
                                </video>
                              </td>
                              <td>
                                <Link className=" pointer" to={{ pathname: '/PostPlayView', search: `?id=${data?._id}` }}>
                                  <h6 className="tb-name">
                                    {data?.user?.userName}
                                  </h6>
                                </Link>
                              </td>
                              <td>
                                {localDate(data?.createdOn)}
                              </td>
                              <td>{data?.likeCount}</td>
                              <td>{data?.commentCount}</td>
                              <td>{data?.shareCount}</td>
                              <td>{data?.viewedCount}</td>
                              <td>
                                <Button
                                  id="fade-button"
                                  aria-controls={openStatus ? 'fade-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openStatus ? 'true' : undefined}
                                  onClick={(event) => handleClick(event, data?._id)} size='small'
                                  style={{ textTransform: 'none' }}
                                  variant="contained" color={data?.isActive === true ? "success" : 'error'}
                                >
                                  {data?.isActive === true ? 'Active' : 'Inactive'}
                                </Button>
                                <Menu
                                  id="fade-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={openStatus}
                                  onClose={handleClose}
                                  TransitionComponent={Fade}
                                >
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(true)} >Active</Link></MenuItem>
                                  <MenuItem onClick={handleClose}><Link onClick={() => openStatusPopup(false)}>Inactive</Link></MenuItem>
                                </Menu>
                              </td>
                            </tr>
                          ))}
                          {play?.length === 0 ?
                            <tr>
                              <td className="form-text text-danger">
                                No data </td>
                            </tr> : null}
                        </tbody>
                      </table>
                    </div>
                    <div className='float-end my-2'>
                      <Pagination count={Math.ceil(pagination.count / pageSize)}
                        onChange={handlePageChange} variant="outlined" shape="rounded" color="primary" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Filter Plays
          <IconButton className="float-end" onClick={closeFilterPopup} >
            <i className="fa fa-times fa-xs" aria-hidden="true"></i>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className="form-group">
              <label>Post User</label>
              <select className="form-select" value={inputs?.user ?? ''} onChange={handleInputs} name='user'>
                <option value={""} disabled hidden >Select Post User</option>
                {user?.map((data, index) =>
                  <option key={index} value={data?._id}>{data?.userName}</option>)}
              </select>
            </div>
            <div className="from-group mb-3">
              <label htmlFor="date" className="form-label">
                Post Date
              </label>
              <input
                type="date"
                className="form-control"
                id="date"
                name="postDate"
                value={inputs?.postDate}
                onChange={handleInputs}
                placeholder="Enter Post Date"
              />
            </div>
            <div><button type="button" className="btn btn-cancel  float-end" onClick={resetFilter}>Reset</button>
              <button type="submit" onClick={filterPostList} className="btn btn-save float-end mx-2">Apply</button></div>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={status}>
        <DialogContent>
          <div className="text-center m-4">
            <h5 className="mb-4">Are you sure you want to {active === true ? 'active' : 'inactive'} <br /> the selected Play ?</h5>
            <button type="button" className="btn btn-save mx-3" onClick={handlePlayStatus}>Yes</button>
            <button type="button" className="btn btn-cancel " onClick={closeStatusPopup}>No</button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default Plays;